import React, { useEffect, useRef, useState, useContext } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css"; // Import the video.js CSS
import { VideosContext } from "../../../../../providers/VideosProvider/videosProvider";

export function Player({ playerRef, fragmentPlayerRef }) {
  const context = useContext(VideosContext);
  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(null);

  const videoUrl =
    "https://flask.dev.transcribe.torsunov.ru/download_rus?file_name=cldirajow000qvs0y7zthzujj/0054_%D0%A0%D0%B0%D0%B1%D0%BE%D1%82%D0%B0_%D0%BD%D0%B0%D0%B4_%D0%BE%D1%88%D0%B8%D0%B1%D0%BA%D0%B0%D0%BC%D0%B8_%D0%B2_%D0%BE%D1%82%D0%BD.mp4";

  const restartVideo = () => {
    if (playerRef.current) {
      playerRef.current.currentTime(0);
    }
  };

  // Initialize video.js player
  useEffect(() => {
    if (videoRef.current && !playerRef.current) {
      playerRef.current = videojs(videoRef.current, {
        controls: true,
        autoplay: false,
        preload: "auto",
      });

      playerRef.current.on("loadedmetadata", () => {
        const duration = Math.round(playerRef.current.duration() * 1000);
        setVideoDuration(duration); // Duration in milliseconds
        context.handles.setVideoDuration(duration);
      });

      playerRef.current.on("timeupdate", () => {
        const currentTime = Math.round(playerRef.current.currentTime() * 1000);
        setCurrentTime(currentTime); // Current time in milliseconds
        context.handles.setCurrentTime(currentTime);
      });

      playerRef.current.on("play", () => {
        context.handles.setPlaying(true);
      });

      playerRef.current.on("pause", () => {
        context.handles.setPlaying(false);
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, [videoUrl]);

  // Restart video if context.currentTime is reset to 0
  useEffect(() => {
    if (context.state.currentTime === 0) {
      restartVideo();
    }
    setCurrentTime(context.state.currentTime);
  }, [context.state.currentTime]);

  // Play or pause the video based on the 'playing' prop
  useEffect(() => {
    if (context.state.playing) {
      if (fragmentPlayerRef && fragmentPlayerRef.current) {
        fragmentPlayerRef.current.play();
      }
    } else {
      if (fragmentPlayerRef && fragmentPlayerRef.current) {
        fragmentPlayerRef.current.pause();
      }
    }
  }, [context.state.playing]);

  return (
    <>
      <div className="x_player_video0">
        {Boolean(videoUrl) && (
          <div className="video-js-container" style={{ zIndex: 2, width: "100%" }}>
            <video ref={videoRef} className={`video-js vjs-default-skin x_player_video x_player_video--${context.state.video}`} style={{ width: "100%", height: "500px" }}>
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}

        {Boolean(context.state.video) && Boolean(context.state["mp3"]) && context.state.video && (
          <div className="x_player_video_sett0">
            <div className={`x_player_video_set_1 ${context.state.video === "sm" ? "active" : ""}`} onClick={() => context.handles.setVideo("sm")}>
              <div className="ico">
                <div>crop_7_5</div>
              </div>
            </div>

            <div className={`x_player_video_set_1 ${context.state.video === "md" ? "active" : ""}`} onClick={() => context.handles.setVideo("md")}>
              <div className="ico">
                <div>crop_5_4</div>
              </div>
            </div>

            <div className={`x_player_video_set_1 ${context.state.video === "full" ? "active" : ""}`} onClick={() => context.handles.setVideo("full")}>
              <div className="ico">
                <div>fullscreen</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
