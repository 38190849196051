import React, { ReactNode, useEffect, useState } from "react";

// @ts-ignore
// import video_final from "../../video/final_video_with_synt.mp4";
import main_lecture from "../../video/main_lecture_AI_processing.json";
import { IVideoHandles, IVideoProvider, ITextLine } from "./types";

const VideosContext = React.createContext<IVideoProvider>({} as IVideoProvider);

const debug = true;
const offline = false;

const VideosProvider = ({ children }: { children: ReactNode }) => {
  const [playing, setPlaying] = useState<boolean>(false);
  const [video, setVideo] = useState<string>("md");
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [zoomLevel, setZoomLevel] = useState<number>(80);

  const [videoAiOptions, setVideoAiOptions] = useState<typeof main_lecture>();

  const [changed, setChanged] = useState<never[]>([]);
  const [playingFragment, setPlayingFragment] = useState<string | undefined>();
  const [playingFragmentNext, setPlayingFragmentNext] = useState<string | undefined>();

  const [browserVisible, setBrowserVisible] = useState(true);
  // const [usedVideos, setUsedVideos] = useState();

  useEffect(() => {
    //TODO: сделать загрузку с сервера
    setVideoAiOptions(main_lecture);
  }, []);

  // const parseData = (js) => {
  //   // let parsed = js.map((one, ind, arr) => ({
  //   //   ...one,
  //   //   // id: one.topic.replaceAll(' ', '_') + '_' + one.topic_text.replaceAll(' ', '_'),
  //   //   start: one.start_time * 1000,
  //   //   end: one.end_time * 1000,
  //   //   length: (one.end_time - one.start_time) * 1000,
  //   //   chosenVideo: one.chosenVideo ? one.chosenVideo : one.relevant_videos[0].id,
  //   //   relevant_videos: [
  //   //     ...one.relevant_videos
  //   //       .filter((oneF) => oneF.video_url)
  //   //       .map((oneF) => ({
  //   //         ...oneF,
  //   //         length: oneF.duration * 1000,
  //   //       })),
  //   //   ],
  //   // }));
  //   // parsed = parsed.map((one, ind, arr) => {
  //   // const prev = arr[ind - 1];
  //   // const next = arr[ind + 1];
  //   // if (next && prev) return ({
  //   //   ...one,
  //   //   lane:
  //   //     (prev.start + prev.length < one.start) && (next.start < (one.start + one.length))
  //   //       ? 2 : 1,
  //   // })
  //   // if (next && !prev) return ({
  //   //   ...one,
  //   //   lane: (next.start < (one.start + one.length))
  //   //     ? 2 : 1,
  //   // })
  //   // if (!next && prev) return ({
  //   //   ...one,
  //   //   lane: prev.start + prev.length < one.start ? 1 : 2,
  //   // })
  //   //   return {
  //   //     ...one,
  //   //     lane: ind,
  //   //   };
  //   // });
  //   // console.log('parsed = ', parsed);
  //   // return parsed;
  // };

  const tryParse = (string: string) => {
    try {
      string = JSON.parse(string);
      return string;
    } catch (error) {
      console.log("wrong format");
      return false;
    }
  };

  const getTextDataFromLocal = () => {
    let d = localStorage.getItem("addedTextData");
    if (d !== null) {
      return tryParse(d) as unknown as ITextLine[];
    }
    return [] as ITextLine[];
  };

  const [textDataInner, setTextDataInner] = useState<ITextLine[]>([]);

  useEffect(() => {
    console.log("videoAiOptions", videoAiOptions);
    if (videoAiOptions) {
      // TODO change for right menu it shoud be object not array
      setData(getTextDataFromLocal());
    }
  }, [videoAiOptions]);

  const setData = (newData: Array<ITextLine> = []) => {
    setTextDataInner(newData);
    console.log("newData", newData);
    localStorage.setItem("addedTextData", JSON.stringify(newData));
  };

  const addNewTextLane = (newLane: ITextLine) => {
    const prev: ITextLine[] = JSON.parse(JSON.stringify(textDataInner));
    setData([newLane, ...prev]);
  };

  const removeLane = (id: string) => {
    const prev: ITextLine[] = JSON.parse(JSON.stringify(textDataInner));
    setData([...prev.filter((one) => one.id !== id)]);
  };

  useEffect(() => {
    const currentFragment = textDataInner.filter((one: ITextLine) => currentTime >= one.start_time && currentTime < one.end_time);
    if (currentFragment.length) {
      const frag = textDataInner.filter((one) => one.id === currentFragment[0].id)[0];
      //const getSelected = (frag) => {
      //  return frag.relevant_videos.filter(one => one.id === frag.chosenVideo)[0]
      //}
      //const url = getSelected(frag).video_url;
      //const currentId = getSelected(frag).id;
      setPlayingFragment(frag.id);
    } else {
      setPlayingFragment(undefined);
    }
  }, [currentTime]);

  // const getVideoUrlFromFragmentId: IVideoHandles["getVideoUrlFromFragmentId"] = (id: string) => {
  //   const frag = textDataInner.filter((one) => one.id === id)[0];
  //   const getSelected = (frag: ITextLine) => {
  //     return frag.relevant_videos.filter((one) => one.id === frag.chosenVideo)[0];
  //   };
  //   const url = getSelected(frag).video_url;
  //   return url;
  // };

  const saveTextData = () => {
    localStorage.setItem("addedTextData", JSON.stringify(textDataInner));
  };

  const changeText = (newData: Partial<ITextLine>) => {
    let prevData = JSON.parse(JSON.stringify(textDataInner));
    prevData = prevData.map((one: ITextLine) => {
      let newD = {};
      if (newData.id === one.id) {
        newD = { ...newData };
      }
      return { ...one, ...newD };
    });
    setData(prevData);
    console.log("JSON.stringify(prevData)", JSON.stringify(prevData));
    localStorage.setItem("addedTextData", JSON.stringify(prevData));
  };

  const handles: IVideoHandles = {
    // getVideoUrlFromFragmentId,
    setChanged,
    setPlayingFragment,
    setPlayingFragmentNext,
    setZoomLevel,
    setPlaying,
    setVideo,
    setVideoDuration,
    setCurrentTime,
    changeText,
    saveTextData,
    addNewTextLane,
    removeLane,
    setBrowserVisible,
  };

  return (
    <VideosContext.Provider
      value={{
        fragments: textDataInner,
        videoDuration,
        video,
        videoAiOptions,
        state: {
          browserVisible,
          video,
          currentTime,
          changed,
          playingFragment,
          playingFragmentNext,
          zoomLevel,
          playing,
          audioDuration: videoDuration,
          durationFull: videoDuration,
        },
        handles,
      }}
    >
      {children}
    </VideosContext.Provider>
  );
};

const VideosConsumer = VideosContext.Consumer;

export { VideosContext, VideosProvider, VideosConsumer };
