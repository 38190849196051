import React, { Fragment, useEffect, useState, useContext, useRef, useCallback } from "react";
import { ReelsDragging } from "../../../../ReelsDragging";
import { ReelsVideoOptions } from "../../../../ReelsVideoOptions";
import { Time } from "../../../../Time";
import { PopoverH } from "../../../../UiKit/PopoverH";
import { TooltipH } from "../../../../UiKit/TooltipH";

import { ButtonH } from "../../../../UiKit/ButtonH";

import { Ico } from "../../../../Uikit";
import { HorizontalSlider } from "../../../../HorizontalSlider";
import { VideosContext } from "../../../../../providers/VideosProvider/videosProvider";
import { useThrottle } from "../../../../../hooks/useThrottle";
import { TimeLine } from "../TimeLine";
import uniqid from "uniqid";
import { TrackAllOrder0SC, TracksContainerCS } from "./styled";
import { TrackDragging } from "./components/TrackDraging";
import { VideosOptions } from "./components/VideosOptions";
import { Button, Input } from "antd";

export const Tracking: React.FC = () => {
  const context = useContext(VideosContext);
  const [zoom, setZoom] = useState(context.state["zoomLevel"]);
  const [textValue, setTextValue] = useState("");
  const zoomLevel = 100 - zoom;
  const trackRef = useRef<HTMLDivElement>(null);
  const [optionsShown, setOptionsShown] = useState(false);
  const [order, setOrder] = useState(context.fragments.map((one) => one.id));
  const throttledValue = useThrottle(zoom, 1000);
  console.log("order", order);
  useEffect(() => {
    // context.handles["setZoomLevel"](throttledValue);
  }, [throttledValue]);

  useEffect(() => {
    if (context.fragments.length !== order.length) {
      setOrder(context.fragments.map((one) => one.id));
    }
  }, [context.fragments]);

  // const blankTrackExist = context.fragments.some((one) => !one.chosenVideo);
  console.log("context.state,zoom", context.state.zoomLevel);
  const handleChangeZoomLevel = useCallback((val: number) => {
    // setZoom(val);
    // (val) => context.handles["setZoomLevel"](val);
  }, []);

  const handleAddText = () => {
    context.handles.addNewTextLane({ text: textValue, start_time: 0, end_time: 100, id: uniqid(), length: 10 });
    setTextValue("");
  };

  const handleInputEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextValue(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="x_tracks0 x_tracks0--videos">
        <TrackAllOrder0SC style={{ position: "absolute", top: "1.4em", right: "2em", zIndex: 1 }}>
          <div className="_fv _fgap1 _fcc">
            <div className="_f">
              <Ico style={{ fontSize: "1.2em" }}>zoom_out</Ico>
              <HorizontalSlider
                value={context.state["zoomLevel"]}
                min={10}
                max={90}
                width={"200px"}
                // onValueChange={(val) =>
                //   Math.floor(context.handles['setActiveSegmentLength'](val + 1) / 10) * 10
                // }
                onValueChange={handleChangeZoomLevel}
              />
              <Ico style={{ fontSize: "1.2em" }}>zoom_in</Ico>
            </div>
          </div>
        </TrackAllOrder0SC>

        <div className="x_track x_track--video" ref={trackRef}>
          <div
            className="x_track_playback inact"
            style={{
              opacity: 0.2,
              width: context.state.currentTime ? `${context.state.currentTime / zoomLevel + 5}px` : "auto",
              mixBlendMode: "multiply",
              left: "4em",
              position: "absolute",
              zIndex: 1000,
            }}
          />

          <TimeLine context={context} style={{ zIndex: 100, top: 0, left: "4em" }} />

          <TracksContainerCS>
            <TrackAllOrder0SC>
              {/*{!context.state.browserVisible && (*/}
              {/*  <div className="x_track_new" onClick={() => context.handles.setBrowserVisible(true)}>*/}
              {/*    <div className={`x_track_new_button ${blankTrackExist ? "inactive" : ""}`}>*/}
              {/*      <div className="t_icon t_icon--40">add</div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*)}*/}
              {/*TODO move order to separate component to implement order change action*/}
              {order.map((oneId) => (
                <div key={`popover_${oneId}_${uniqid()}`} className="x_track_order">
                  <TooltipH placement="right" title={<>Переместить дорожку вверх</>}>
                    <div className="x_track_order_up">
                      <div className="t_icon t_icon--60">
                        <div>publish</div>
                      </div>
                    </div>
                  </TooltipH>

                  <TooltipH placement="right" title={<>Переместить дорожку вниз</>}>
                    <div className="x_track_order_up">
                      <div className="t_icon t_icon--60">
                        <div>download</div>
                      </div>
                    </div>
                  </TooltipH>
                </div>
              ))}
              <div className="x_track_new">
                <PopoverH
                  title={
                    <div className="_fv" style={{ width: "24em" }}>
                      <div>
                        <Input value={textValue} placeholder="Введите текст" onChange={handleInputEvent} />
                      </div>
                      <div>
                        <Button type="primary" onClick={handleAddText}>
                          Добавить
                        </Button>
                      </div>
                    </div>
                  }
                >
                  <div className={`x_track_new_button`}>
                    <div className="t_icon t_icon--40">add</div>
                  </div>
                </PopoverH>
              </div>

              {/*<div className="x_track_new" onClick={handleAddText}>*/}
              {/*  <div className={`x_track_new_button`}>*/}
              {/*    <div className="t_icon t_icon--40">add</div>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*<ReelsDefaukltBgUpload />*/}
            </TrackAllOrder0SC>

            <div
              className={"x_track_all_fragments x_track_all_fragments--reels"}
              style={{
                display: "flex",
                width: context["videoDuration"] ? `${context["videoDuration"] / zoomLevel}px` : "auto",
                height: "auto",
              }}
            >
              {context.fragments.map((oneTrack, ind, arr) => (
                <TrackDragging
                  key={oneTrack.id}
                  order={order.findIndex((one) => one === oneTrack.id)}
                  // optionsShown={optionsShown}
                  context={context}
                  oneTrack={oneTrack}
                  ind={ind}
                  id={oneTrack.id}
                  start={oneTrack.start_time}
                  length={oneTrack.length}
                  tracks={context.fragments}
                  nextTrack={arr[ind + 1] ? arr[ind + 1] : null}
                  prevTrack={arr[ind - 1] ? arr[ind - 1] : null}
                  draggingTrack={trackRef}
                >
                  <VideosOptions optionsShown={optionsShown} {...oneTrack} />
                </TrackDragging>
              ))}
            </div>

            {/*<div className="x_track_all_order0">*/}
            {/*  {order.map((oneId) => (*/}
            {/*    <PopoverH*/}
            {/*      key={`popover_${oneId}_delete_${uniqid()}`}*/}
            {/*      title={*/}
            {/*        <>*/}
            {/*          <ButtonH onClick={() => context.handles.removeLane(oneId)}>Удалить дорожку</ButtonH>*/}
            {/*        </>*/}
            {/*      }*/}
            {/*    >*/}
            {/*      <div className="x_track_order">*/}
            {/*        <Ico style={{ fontSize: "1.2em" }}>delete</Ico>*/}
            {/*      </div>*/}
            {/*    </PopoverH>*/}
            {/*  ))}*/}
            {/*</div>*/}
          </TracksContainerCS>
        </div>
      </div>
    </React.Fragment>
  );
};

// function ReelsDefaukltBgUpload() {
//   return (
//     <>
//       <TooltipH title={<>Загрузить фон по умолчанию</>} placement="right">
//         <div className="x_track_bg0">
//           <div className="x_track_bg" />
//         </div>
//       </TooltipH>
//     </>
//   );
// }
