import { Track, Folder } from "../types";

export const metadataToSubtitlesLanguages = {
  english: "en-US",
  en: "en-US",
  tr: "tr-TR",
  chinese: "ch-CH",
  ch: "ch-CH",
  es: "es-ES",
  lit: "lit-lit",
  de: "de-de",
  ru: "ru-RU",
}

export const getNextStart = (id: string, dataAll: Folder[], folder: number) => {
  const translations = dataAll[folder] ? dataAll[folder]['translations'] : [];
  const currentIndex = translations.findIndex((one) => one['id'] === id);
  if (currentIndex < translations.length - 1) {
    return translations[currentIndex + 1]['start'];
  }
  return getFolderStartTime(dataAll, folder + 1);
}

export const getFolderStartTime = (dataAll: Folder[], folder: number) => {
  if (folder < dataAll.length) {
    const folderData = dataAll[folder];
    if (folderData && folderData['translations'].length) {
      const prevLength = parseInt(folderData['prev_mp3_length_aggr'], 10);
      const shiftedStart = folderData['translations'][0]['shifted_start'];
      return prevLength + shiftedStart;
    }
  }
  return null;
}

export const checkTrimmed = (tracks: Track[], keepLength: boolean, translationDataAll: Folder[], folder: number) => {
  return tracks.map((oneTrack) => {
    const nextTrackStart = getNextStart(oneTrack['original_id'], translationDataAll, folder);
    const trimmed = getTrimmed(oneTrack, nextTrackStart, 'original_length');

    oneTrack['trimmed'] = trimmed;
    if (!keepLength) {
      oneTrack['length'] = trimmed && nextTrackStart ? nextTrackStart - oneTrack['start'] : oneTrack['original_length']
    }
    return oneTrack
  })
}

const getTrimmed = (oneTrack: Track, nextTrackStart: number | null, lengthFieldName: 'original_length' | 'length') => {
  return nextTrackStart && nextTrackStart < oneTrack['start'] + oneTrack[lengthFieldName]
  ? oneTrack['start'] + oneTrack[lengthFieldName] - nextTrackStart
  : null
}

export const prepareDataOne = (one: Track, folder_num: number, allData: Folder[]) => {
  const folder = folder_num - 1;
  let length;

  // if (!one['length']) {
  //   let calulatedLength = timeStringToMs(one['start_rus']) - timeStringToMs(one['end_rus']);
  //   one['length'] = calulatedLength;
  // };
  if (!one['length'] && one['start_rus_milli'] && one['end_rus_milli']) {
    let calulatedLength = one['end_rus_milli'] - one['start_rus_milli'];
    one['length'] = calulatedLength;
  };

  let addLength = allData[folder] ? parseInt(allData[folder]['prev_mp3_length_aggr']) : 0;
  //const trimmed = getTrimmed(one, nextTrackStart, 'length');

  let start_rus_milli = one['start_rus_milli'] ? one['start_rus_milli'] : one['start']
  let end_rus_milli = one['end_rus_milli'] ? one['end_rus_milli'] : one['start'] + one['length']
  start_rus_milli = folder_num ? start_rus_milli - addLength : start_rus_milli
  start_rus_milli = start_rus_milli < 0 ? 0 : start_rus_milli
  end_rus_milli = folder_num ? end_rus_milli - addLength : end_rus_milli;
  const nextTrackStart = getNextStart(one['id'], allData, folder)
  if (nextTrackStart && nextTrackStart < one['start'] + one['length']) {
    length = nextTrackStart - one['start'];
  }
  let trimmed = length ? one['length'] - length : null
  let trimmed_start = null;
  let start = folder_num ? one['start'] - addLength : one['start'];
  if (start < 0) {
    trimmed_start = start * -1;
    start = 0;
  }

  let newDataOne = {
    ...one,
    original_length: one['length'],
    length: length ? length : one['length'],
    trimmed: trimmed,
    trimmed_start: trimmed_start,
    original_start: one['start'],
    original_shifted_start: one['shifted_start'],
    start: start,
    start_rus_milli: start_rus_milli,
    end_rus_milli: end_rus_milli,
    original_id: one['id'],
    id: `${one['folder_num']}_${one['id']}`,
  }

  return newDataOne
}

export const Database = {
  name: "videos",
  version: 4,
  videoTable: "video",
  audioTable: "audio",
};

export const getSpacesApplied = (spaces: Track[], voicingSpacesDeleted: string[]) => {
  return spaces.filter(one => !voicingSpacesDeleted.includes(one.nextTrackId))
    .map((track, index) => {
      if (index === 0) {
        return [0, Number(track.start)]
      } else {
        return [Number(spaces[index - 1].end), Number(track.start)]
      }
    })
};

export const getPrevTrackId = (currentTrackId: string, tracks: Track[]) => {
  const currentIndex = tracks.findIndex(track => track.id === currentTrackId);
  return currentIndex === 0 || currentIndex === -1 ? tracks[0]['id'] : tracks[currentIndex - 1].id;
};
