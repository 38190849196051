import styled, { css } from "styled-components";

export const TrackStartSC = styled.div`
  z-index: 100;
  background-color: var(--accent);
  opacity: 1;
  width: 2px;
  transition: opacity 0.2s;
  position: absolute;
  inset: -11em auto -200px 0%;
`;

export const TrackHoverSC = styled.div<{ isVideo?: boolean; top?: string }>`
  background-color: var(--gray);
  opacity: 0.5;
  mix-blend-mode: multiply;
  border-radius: 0.4em;
  height: 4em;
  transition: opacity 0.2s;
  position: absolute;
  inset: 0 0 auto;
  top: ${({ top }) => top || 0};
  box-shadow:
    0 1px #ffffff96,
    inset 0 3px 8px #0000,
    inset 0 3px 11px -6px #000000b8;

  ${({ isVideo }) =>
    isVideo &&
    css`
      background-color: var(--black);
      box-shadow:
        0 1px #5a595996,
        inset 0 3px 8px #000,
        inset 0 3px 11px -6px #000000b8;
    `}
`;
