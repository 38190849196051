import React, { useState, useRef, useEffect, useCallback } from "react";
import { ITextLine, IVideoProvider } from "../../../../../../../providers/VideosProvider/types";
import { TrackDragIcon } from "../../../../../../elements/icons/TrackDragIcon";
import { TrackHoverSC, TrackStartSC } from "../../../../../../layouts/videoFull/videoFull.styled";

interface ITrackDraggingProps {
  order?: number;
  context: IVideoProvider;
  id: string;
  ind: any;
  oneTrack: ITextLine;
  // marginLeft,
  // marginRight,
  handlePlayNext?: () => void;
  // clickHandler,
  start: number;
  length: number;
  // trimmed,
  // trimmed_start,
  // end_rus_milli,
  // start_rus_milli,
  // totalLength,
  tracks: ITextLine[];
  nextTrack: ITextLine | null;
  prevTrack: ITextLine | null;
  onUpdatePosition?: () => void;
  onUpdateLength?: () => void;
  // timeoutRef,
  // openEditor,
  draggingTrack: React.RefObject<HTMLDivElement | null>;
  // subtitles = "",
  // setNewData,
  children: React.ReactNode;
  optionsShown?: string;
}
export const TrackDragging: React.FC<ITrackDraggingProps> = React.memo(
  ({
    order = 0,
    // topic,
    context,
    id,
    ind,
    oneTrack,
    // marginLeft,
    // marginRight,
    // handlePlayNext = () => void 0,
    // clickHandler,
    start,
    length,
    // trimmed,
    // trimmed_start,
    // end_rus_milli,
    // start_rus_milli,
    // totalLength,
    tracks,
    nextTrack,
    prevTrack,
    onUpdatePosition = () => void 0,
    onUpdateLength = () => void 0,
    // timeoutRef,
    // openEditor,
    draggingTrack,
    // subtitles = "",
    // setNewData,
    children,
    optionsShown,
  }) => {
    const zoomLevel = 100 - context.state["zoomLevel"];
    const [position, setPosition] = useState(start);
    const [lengthPosition, setLengthPosition] = useState(length);
    const [isDragging, setIsDragging] = useState(false);
    const [isLengthing, setIsLengthing] = useState(false);
    const [startX, setStartX] = useState(0);
    const [lengthX, setLengthX] = useState(0);
    const [dragStart, setDragStart] = useState(false);
    const [textNewPosition, setTextNewPosition] = useState<Partial<ITextLine>>(() => oneTrack);
    const dragHandleRef = useRef<HTMLDivElement>(null);
    const dragHandleRefStart = useRef<HTMLDivElement>(null);
    const lengthHandleRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (length !== lengthPosition) {
        setLengthPosition(length);
      }
    }, [length]);
    console.log("tracks", tracks);
    const getTrackClass = (track: ITextLine, tracks: ITextLine[]) => {
      const { playingFragment, playingFragmentNext, changed } = context.state;
      const trackId = track.id;
      console.log("getTrackClass");
      let className = "x_track_fragment x_track_fragment--reel";

      // className += ` x_track_fragment--${track.lane}`

      // if (nextTrack && nextTrack.start < track.start + track.duration) {
      //   className += ' x_track_fragment--2'
      // }
      // if (changed.includes(trackId)) className += " changed";
      // if (trackId === playingFragment) className += " active";

      // if (trimmed) className += " trimmed";
      // if (context.state.changedRecently.includes(id)) className += " changed_recently";

      // Determine if this track is the next one to play and is currently in the delay period
      // const isNextTrackInDelay = trackId === playingFragmentNext && timeoutRef.current;
      // const isNextTrackInDelay = 0;

      // if (isNextTrackInDelay || trackId === context.handles.getNextTrackId(playingFragment, tracks)) {
      //   className += " next";
      // }

      return className;
    };

    const handlePositionMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
      console.log("handlePositionMouseDown");
      if (e.type === "touchstart") {
        e.preventDefault();
      }
      if (dragHandleRef.current && e.target instanceof Node && dragHandleRef.current.contains(e.target)) {
        setIsDragging(true);
        setStartX(e.clientX);
      }

      if (dragHandleRefStart.current && e.target instanceof Node && dragHandleRefStart.current.contains(e.target)) {
        setIsDragging(true);
        setDragStart(true);
        setStartX(e.clientX);
      }
    };

    const handleLengthMouseDown = (e: React.MouseEvent) => {
      if (e.type === "touchstart") {
        e.preventDefault();
      }
      if (lengthHandleRef.current && e.target instanceof Node && lengthHandleRef.current.contains(e.target)) {
        setIsLengthing(true);
        setLengthX(e.clientX);
      }
    };

    const getMaxSpace = useCallback(() => {
      const currentIndex = tracks.findIndex((t) => t.id === id);
      const isLastTrack = currentIndex === tracks.length - 1;
      console.log("currentIndex", currentIndex);
      let min = 0;
      let max = context.state.durationFull;

      // min = currentIndex > 0
      //   ? tracks[currentIndex - 1]['start'] + tracks[currentIndex - 1]['length']
      //   : 0;
      // // max = context.state['durationFull'] - length;
      // max = isLastTrack
      //   ? context.state['durationFull']
      //   : tracks[currentIndex + 1]['start'];

      // // Correction for the max calculation for the last track
      // // if (isLastTrack) {
      // //   max = context.state['durationFull'] - length;
      // // }
      // console.log('min = ', min);
      // console.log('max = ', max);

      // console.log(`Track ID: ${id}, Min: ${min}, Max: ${max}, Is Last Track: ${isLastTrack}`);
      return { min, max };
    }, [tracks, id, context.state.durationFull]);

    const getMaxLength = () => {
      const currentIndex = tracks.findIndex((t) => t.id === id);
      const isLastTrack = currentIndex === tracks.length - 1;

      let min = 1000;
      let what = "start_time" as keyof ITextLine; //"start";
      let max = isLastTrack ? context.state["durationFull"] : tracks[currentIndex + 1][what];

      // Correction for the max calculation for the last track
      if (isLastTrack) {
        max = context.state["durationFull"];
      }

      return { min, max };
    };

    const handleMouseMove = useCallback(
      (e: MouseEvent | TouchEvent) => {
        let clientX = 0;
        if (e.type === "touchmove") {
          clientX = (e as TouchEvent).touches[0].clientX;
        }
        if (e.type === "mousemove") {
          clientX = (e as MouseEvent).clientX;
        }
        if (isDragging) {
          const { min, max } = getMaxSpace();
          const deltaX = clientX - startX;
          const newPosition = position + deltaX * zoomLevel;
          if (newPosition >= min && newPosition <= max) {
            setPosition(newPosition);
            let newLengthPosition = lengthPosition;

            if (dragStart) {
              newLengthPosition = lengthPosition - deltaX * zoomLevel;
              if (newLengthPosition >= min && newLengthPosition <= max) {
                setLengthPosition(newLengthPosition);
              }
            }

            setStartX(clientX);
            setTextNewPosition({ id, start_time: newPosition, end_time: newPosition + newLengthPosition, length: newLengthPosition });
          }
        }
        if (isLengthing) {
          const { min, max } = getMaxLength();
          console.log("getMaxLength()", min, max);
          const deltaX = clientX - lengthX;
          const newLengthPosition = lengthPosition + deltaX * zoomLevel;
          // console.log('newLengthPosition = ', newLengthPosition);
          // The new length of the fragment

          // if (newLengthPosition >= min && newLengthPosition + position <= max) {
          setLengthPosition(newLengthPosition);
          setLengthX(clientX);
          // TODO change it after debounce
          // context.handles.changeText({ id, start_time: position, end_time: position + newLengthPosition, length: newLengthPosition });
          // }
        }
      },
      [isDragging, startX, position, lengthPosition, dragStart, isLengthing, lengthX, getMaxSpace, id],
    );

    useEffect(() => {
      const handleMouseUp = () => {
        if (isDragging || isLengthing) {
          console.log("endDrugging");
          context.handles.changeText(textNewPosition);
        }
        setIsDragging(false);
        setIsLengthing(false);
        setDragStart(false);
      };

      if (isDragging || isLengthing) {
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);
        // Add touch event listeners
        window.addEventListener("touchmove", handleMouseMove);
        window.addEventListener("touchend", handleMouseUp);
      }

      return () => {
        if (isDragging || isLengthing) {
          window.removeEventListener("mousemove", handleMouseMove);
          window.removeEventListener("mouseup", handleMouseUp);

          // Remove touch event listeners
          window.removeEventListener("touchmove", handleMouseMove);
          window.removeEventListener("touchend", handleMouseUp);
        }
      };
    }, [isDragging, isLengthing, position, lengthPosition]);

    useEffect(() => {
      // console.log('oneTrack[captions] = ', oneTrack['captions']);
    }, [oneTrack]);

    return (
      <>
        <TrackHoverSC isVideo top={`${order * 4.2}em`} />h
        <div
          style={{
            zIndex: optionsShown === id ? 1000 : 0,
            position: "absolute",
            left: `${position / zoomLevel}px`,
            top: `${order * 4.2}em`,
            width: `${lengthPosition / zoomLevel}px`,
            cursor: "pointer",
            // boxShadow: `${!subtitles && trimmed ? `${trimmed / zoomLevel}px 0 0 0px rgba(255, 0, 0, 0.7)` : 'none'}`,
            // boxShadow: `${!subtitles && trimmed ? `${trimmed / zoomLevel}px 0 0 0px rgba(255, 0, 0, 0.7)` : `${trimmed_start ? `inset ${trimmed_start / zoomLevel}px 0 0 0px rgba(255, 0, 0, 0.7)` : ''}`}`,
            // zIndex: `${trimmed ? 1 : 'inherit'}`,
            // background: trimmed_start ? 'red' : 'inherit',
            // overflowX: 'hidden',
            // overflowY: 'auto',
            // height: subtitles ? '1em' : '4em'
            // height: '4em'
          }}
          className={getTrackClass(oneTrack, tracks)}
          // key={`${context.state['mediaId']}_${oneTrack['id']}`}
          // draggable="true"
          // onClick={handleClick}
        >
          {children}
          {(isDragging || isLengthing) && <TrackStartSC />}

          <div onMouseDown={handlePositionMouseDown}>
            {!isDragging && ( // (!Boolean(oneTrack["file"]) || Boolean(oneTrack["add_background"]))
              <div className={isLengthing ? "x_track_length0 dragging" : "x_track_length0"}>
                <div className="x_track_length" ref={lengthHandleRef} onMouseDown={handleLengthMouseDown}>
                  {!isLengthing && <div className="ico">drag_indicator</div>}
                  {isLengthing && <TrackDragIcon />}
                </div>
              </div>
            )}

            {true && ( // !Boolean(oneTrack["file"])
              <div className={isLengthing ? "x_track_length0 x_track_length0--start dragging" : "x_track_length0 x_track_length0--start"}>
                <div className="x_track_length x_track_length--start" ref={dragHandleRefStart}>
                  {!isLengthing && <div className="ico">drag_indicator</div>}
                  {isLengthing && (
                    <svg className="x_track_drag-icon" width={20} height={14} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7 1.2071V12.7929C7 13.2383 6.46143 13.4614 6.14645 13.1464L0.707108 7.7071C0.316584 7.31658 0.316585 6.68341 0.707108 6.29289L6.14645 0.853549C6.46143 0.538567 7 0.761651 7 1.2071ZM13 1.2071V12.7929C13 13.2383 13.5386 13.4614 13.8536 13.1464L19.2929 7.7071C19.6834 7.31658 19.6834 6.68341 19.2929 6.29289L13.8536 0.853549C13.5386 0.538567 13 0.761651 13 1.2071Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </div>
              </div>
            )}

            {/* {(!Boolean(oneTrack['file'])) && <>
          <Overlay style={{ color: 'white', fontSize: '0.6em' }}>
            <Flex gap='.2em'>
              <Ico>music_off</Ico>Нет файла
            </Flex>
          </Overlay>
        </>} */}

            <div
              style={{
                overflow: "hidden",
                width: lengthPosition / zoomLevel,
              }}
            ></div>

            {!isLengthing && (
              <div className="x_track_drag0" ref={dragHandleRef} style={{ top: "-1em", bottom: "auto" }}>
                <div className="x_track_drag-handle">
                  <svg className="x_track_drag-icon" width={20} height={14} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7 1.2071V12.7929C7 13.2383 6.46143 13.4614 6.14645 13.1464L0.707108 7.7071C0.316584 7.31658 0.316585 6.68341 0.707108 6.29289L6.14645 0.853549C6.46143 0.538567 7 0.761651 7 1.2071ZM13 1.2071V12.7929C13 13.2383 13.5386 13.4614 13.8536 13.1464L19.2929 7.7071C19.6834 7.31658 19.6834 6.68341 19.2929 6.29289L13.8536 0.853549C13.5386 0.538567 13 0.761651 13 1.2071Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div
                  className="x_track_fragment_drag"
                  // style={{
                  //   // left: 0,
                  //   // right: 0,
                  //   left: Math.floor(marginLeft / zoomLevel * -1),
                  //   right: Math.floor(marginRight / zoomLevel * -1),
                  // }}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  },
);
