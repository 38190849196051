import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import { Context } from '../contextSubtitles';
import { formatTimeMilli, FRAGMENT_MIN_HEIGHT } from './utils';

export const DraggingSubtitle = React.memo(({
  id,
  ind,
  oneTrack,
  start,
  length,
  trimmed,
  trimmed_start,
  tracks,
  timeoutRef,
  openEditor,
  draggingTrack,
}) => {
  const context = useContext(Context);
  const zoomLevel = 100 - context.state['zoomLevel'];
  const [position, setPosition] = useState(start);
  const [lengthPosition, setLengthPosition] = useState(length);
  const [isDragging, setIsDragging] = useState(false);
  const [isLengthing, setIsLengthing] = useState(false);
  const [isLengthingUpper, setIsLengthingUpper] = useState(false);
  const [startY, setStartX] = useState(0);
  const [lengthX, setLengthX] = useState(0);

  useEffect(() => {
    if (length !== lengthPosition) {
      setLengthPosition(length)
    }
  }, [length])

  const clickHandler = () => {
    console.log('CLICKED = ', oneTrack);
    context.handles.setPlayingFragment(oneTrack['id']);
    const newStart = position;
    context.handles.setPlayStart(newStart);
    context.handles.setPlayOriginalStart(oneTrack['original_start']);
    context.handles.setPlaying(true);
    context.handles['setZoomFragment']([startY / zoomLevel, (startY + context.state.scrubberWidth) / zoomLevel]);
    context.handles.setPlaybackTime(oneTrack['start'])
    context.handles['setPlayingFragmentNext']();
    context.handles.changeTracksSelected(oneTrack.original_id);
  }

  const getTrackClass = (trackId, tracks) => {
    const { playingFragment, playingFragmentNext, changed } = context.state;
    // const playingFragment = id;

    let className = "x_track_fragment x_track_fragment--v";
    if (changed.includes(trackId)) className += " changed";
    if (trackId === playingFragment) className += " active";
    if (trimmed) className += " trimmed";
    if (context.state.changedRecently.includes(id)) className += " changed_recently";

    // Determine if this track is the next one to play and is currently in the delay period
    const isNextTrackInDelay = trackId === playingFragmentNext && timeoutRef.current;

    if (isNextTrackInDelay || trackId === context.handles.getNextTrackId(playingFragment, tracks)) {
      className += " next";
    }

    return className;
  };

  const dragHandleRef = useRef(null);
  const dragHandleRefStart = useRef(null);
  const lengthHandleRef = useRef(null);

  const handlePositionMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation(); // Add this line to stop event propagation

    if (dragHandleRef.current && dragHandleRef.current.contains(e.target)) {
      setIsDragging(true);
      setStartX(e.clientY);
    }

    if (dragHandleRefStart.current && dragHandleRefStart.current.contains(e.target)) {
      setIsLengthingUpper(true)
      setStartX(e.clientY);
    }

    if (
      dragHandleRef.current &&
      !dragHandleRef.current.contains(e.target) &&
      !lengthHandleRef.current?.contains(e.target) &&
      !dragHandleRefStart.current?.contains(e.target)
    ) {
      clickHandler();
    }
  };
  const handleLengthMouseDown = (e) => {
    e.preventDefault();
    if (lengthHandleRef.current && lengthHandleRef.current.contains(e.target)) {
      setIsLengthing(true);
      setLengthX(e.clientY);
    }
  }

  const getMaxLength = () => {
    const isLastTrack = ind === tracks.length - 1;

    const min = ind === 0 ? 0 : tracks[ind - 1]['end_rus_milli'];
    const max = isLastTrack ? context.state['durationFull'] : tracks[ind + 1]['start_rus_milli'];

    return { min, max };
  };


  const getMaxSpace = () => {
    const min = 0;
    const max = context.state['durationFull'] - length;

    // console.log(`Track ID: ${id}, Min: ${min}, Max: ${max}, Is Last Track: ${isLastTrack}`);
    return { min, max };
  };

  const handleMouseMove = useCallback((e) => {
    e.preventDefault();
    const { min, max } = getMaxLength();
    if (isDragging || isLengthingUpper) {
      const deltaX = e.clientY - startY;
      const newPosition = position + deltaX * zoomLevel;
      const newLengthPosition = lengthPosition - (deltaX * zoomLevel);

      if (newPosition >= min && (isDragging ? lengthPosition : 0) + newPosition <= max && newLengthPosition > 0) {
        setPosition(newPosition);
        if (isLengthingUpper) {
          if (newPosition + newLengthPosition >= min
            && newPosition + newLengthPosition <= max
          ) {
            setLengthPosition(newLengthPosition)
          }
        }

        setStartX(e.clientY);
        context.handles.addNewChange(id, 'changed');
      }
    }
    if (isLengthing) {
      const deltaX = e.clientY - lengthX;
      const newLengthPosition = lengthPosition + deltaX * zoomLevel;
      if (newLengthPosition > 1 && position + newLengthPosition <= max) {
        setLengthPosition(newLengthPosition);
        setLengthX(e.clientY);
        context.handles.addNewChange(id, 'changed');
      }
    }
  }, [isDragging, isLengthing, position, lengthPosition, startY, lengthX, zoomLevel, isLengthingUpper, id, getMaxSpace,]);

  useEffect(() => {
    if (
      !isDragging &&
      startY &&
      startY !== context.state['translationData'][ind]['start']
    ) {
      (async () => {
        try {
          await context.handles.updatePosition(id, position, lengthPosition, tracks);
        } catch (error) {
          console.error('Error updating position or length:', error);
        }
      })();
    }
  }, [isDragging]);

  useEffect(() => {
    if (
      !isLengthingUpper &&
      startY &&
      startY !== context.state['translationData'][ind]['start']
    ) {
      (async () => {
        try {
          await context.handles.updateStart(id, position, tracks, true);
        } catch (error) {
          console.error('Error updating position:', error);
        }
      })();
    }
  }, [isLengthingUpper]);

  useEffect(() => {
    if (
      !isLengthing &&
      lengthX &&
      lengthX !== context.state['translationData'][ind]['length']
    ) {
      (async () => {
        try {
          await context.handles.updateLength(id, lengthPosition, tracks, true);
        } catch (error) {
          console.error('Error updating length:', error);
        }
      })();
    }
  }, [isLengthing]);

  const handleMouseUp = (e) => {
    e.preventDefault();
    setIsDragging(false);
    setIsLengthing(false);
    setIsLengthingUpper(false);
  };

  useEffect(() => {
    if (draggingTrack.current) {
      draggingTrack.current.addEventListener('pointermove', handleMouseMove);
      draggingTrack.current.addEventListener('pointerup', handleMouseUp);
    }
    return () => {
      if (draggingTrack.current) {
        draggingTrack.current.removeEventListener('pointermove', handleMouseMove);
        draggingTrack.current.removeEventListener('pointerup', handleMouseUp);
      }
    };
  }, [isDragging, isLengthing, handleMouseMove, handleMouseUp]);

  const handleTouchStart = (e) => {
    if (dragHandleRef.current && dragHandleRef.current.contains(e.target)) {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
      setStartX(e.touches[0].clientY);
    } else if (dragHandleRefStart.current && dragHandleRefStart.current.contains(e.target)) {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
      setIsLengthingUpper(true);
      setStartX(e.touches[0].clientY);
    }
    // If the touch is outside the drag handles, do nothing, allowing parent scroll
  };

  const handleTouchMove = useCallback((e) => {
    if (isDragging || isLengthingUpper) {
      e.preventDefault();
      e.stopPropagation();
      const touch = e.touches[0];
      const { min, max } = getMaxSpace();
      const deltaX = touch.clientY - startY;
      const newPosition = position + deltaX * zoomLevel;

      if (newPosition >= min && newPosition <= max) {
        setPosition(newPosition);

        if (isLengthingUpper) {
          const newLengthPosition = lengthPosition - (deltaX * zoomLevel);
          if (newLengthPosition >= min && newLengthPosition <= max) {
            setLengthPosition(newLengthPosition);
          }
        }

        setStartX(touch.clientY);
        context.handles.addNewChange(id, 'changed');
      }
    }
    // If not dragging, do nothing, allowing parent scroll
  }, [isDragging, position, lengthPosition, startY, zoomLevel, isLengthingUpper, id, getMaxSpace]);

  const handleTouchEnd = (e) => {
    if (isDragging) {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      setIsLengthingUpper(false);
    }
  };

  useEffect(() => {
    if (draggingTrack.current) {
      draggingTrack.current.addEventListener('touchstart', handleTouchStart, { passive: false });
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
      document.addEventListener('touchend', handleTouchEnd);
    }
    return () => {
      if (draggingTrack.current) {
        draggingTrack.current.removeEventListener('touchstart', handleTouchStart);
      }
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handleTouchMove]);

  return (

    <div
      style={{
        position: 'absolute',
        top: `${position / zoomLevel}px`,
        height: `${Math.max(lengthPosition / zoomLevel, FRAGMENT_MIN_HEIGHT)}px`,
        cursor: 'pointer',
        boxShadow: trimmed_start ? `inset ${trimmed_start / zoomLevel}px 0 0 0px rgba(255, 0, 0, 0.7)` : '',
        zIndex: `${trimmed ? 1 : 'inherit'}`,
      }}
      className={getTrackClass(oneTrack.id, tracks)}
      onMouseDown={handlePositionMouseDown}
    >
      <div className="x_track_fragment_startstop">
        {formatTimeMilli(oneTrack['start_rus_milli'])}
      </div>

      <div className="x_track_fragment_startstop x_track_fragment_startstop--2">
        {formatTimeMilli(oneTrack['end_rus_milli'])}
      </div>


      <div className="x_track_start_hor" />

      <div>

        {!isDragging && <div className={isLengthing ? "x_track_length0 x_track_length0--v dragging" : "x_track_length0 x_track_length0--v"}>
          <div className="x_track_length x_track_length--v" ref={lengthHandleRef}
            onMouseDown={handleLengthMouseDown}
          >
            {!isLengthing && <div className="ico ico--v">drag_indicator</div>}
            {isLengthing && <svg
              className='x_track_drag-icon'
              width={20} height={14} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7 1.2071V12.7929C7 13.2383 6.46143 13.4614 6.14645 13.1464L0.707108 7.7071C0.316584 7.31658 0.316585 6.68341 0.707108 6.29289L6.14645 0.853549C6.46143 0.538567 7 0.761651 7 1.2071ZM13 1.2071V12.7929C13 13.2383 13.5386 13.4614 13.8536 13.1464L19.2929 7.7071C19.6834 7.31658 19.6834 6.68341 19.2929 6.29289L13.8536 0.853549C13.5386 0.538567 13 0.761651 13 1.2071Z" fill="white" />
            </svg>}
          </div>
        </div>}

        <div className={isLengthing ? "x_track_length0 x_track_length0--v x_track_length0--start dragging" : "x_track_length0 x_track_length0--v x_track_length0--start"}>
          <div className="x_track_length x_track_length--v x_track_length--start"
            ref={dragHandleRefStart}
          >
            {!isLengthing && <div className="ico ico--v">drag_indicator</div>}
            {isLengthing && <svg
              className='x_track_drag-icon'
              width={20} height={14} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7 1.2071V12.7929C7 13.2383 6.46143 13.4614 6.14645 13.1464L0.707108 7.7071C0.316584 7.31658 0.316585 6.68341 0.707108 6.29289L6.14645 0.853549C6.46143 0.538567 7 0.761651 7 1.2071ZM13 1.2071V12.7929C13 13.2383 13.5386 13.4614 13.8536 13.1464L19.2929 7.7071C19.6834 7.31658 19.6834 6.68341 19.2929 6.29289L13.8536 0.853549C13.5386 0.538567 13 0.761651 13 1.2071Z" fill="white" />
            </svg>}
          </div>
        </div>

        {context.state.waitingCut.includes(oneTrack.original_id) && <div className="p_b_preloader"><img src="https://assets-global.website-files.com/651f0451acd1cf1eb674532d/657223dc690f13a86f06e953_preloader.gif" loading="lazy" className="preloadergif" /></div>}

        {/*<div style={{
          overflow: 'hidden',
          width: lengthPosition / zoomLevel,
          opacity: context.state.waitingCut.includes(oneTrack.original_id) ? 0.3 : 1,
        }} >
          
      </div>*/}

        {!isLengthing && <div className="x_track_drag0 x_track_drag0--v" ref={dragHandleRef}>
          <div className="x_track_drag-handle x_track_drag-handle--v">
            <svg
              className='x_track_drag-icon'
              width={20} height={14} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7 1.2071V12.7929C7 13.2383 6.46143 13.4614 6.14645 13.1464L0.707108 7.7071C0.316584 7.31658 0.316585 6.68341 0.707108 6.29289L6.14645 0.853549C6.46143 0.538567 7 0.761651 7 1.2071ZM13 1.2071V12.7929C13 13.2383 13.5386 13.4614 13.8536 13.1464L19.2929 7.7071C19.6834 7.31658 19.6834 6.68341 19.2929 6.29289L13.8536 0.853549C13.5386 0.538567 13 0.761651 13 1.2071Z" fill="white" />
            </svg>
          </div>
        </div>}
      </div>
      <div className="x_track_start_hor bottom" />

      {context.state.video !== 'full' && <div className={`x_track_cc x_track_cc--v ${context.state.tracksSelected.includes(oneTrack.original_id) && "selected"}`}>
        <span>
          {(!context.state.lang || context.state.lang === "ru") ?
          (oneTrack.captions_rus ?? oneTrack.captions) : oneTrack.captions}

          <div style={{display: "flex", gap: "2em"}}>
            <div className="x_track_edit" onClick={() => { openEditor(oneTrack) }}>
              <div className="ico inact">
                edit
              </div>
            </div>
            <div className="x_track_edit" onClick={() => { context.handles.mergeCaptions(oneTrack) }}>
              <div className="ico inact">
                merge
              </div>
            </div>
          </div>
        </span>
        {trimmed_start && <span style={{ color: 'red' }}>⚠️ Начало фрагмента за пределами</span>}
      </div>}
    </div>
  );
})
