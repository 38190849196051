const API_URL = process.env.REACT_APP_API_URL;

export const segmentsToKeep = async (mediaId: string, json: Object) => {
    try {
      const response = await fetch(`${API_URL}/segments_to_keep?media=${mediaId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(json),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Segments to keep response:', data);
      /*fetchTracks(mediaId, () => {

      })*/

      return data;
    } catch (error) {
      console.error('Error segments to keep call:', error);
      throw error;
    }
  };
  