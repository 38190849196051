import React, { useEffect, useRef, useState, useContext } from 'react';
import { Context } from '../context';
import { PreloaderGif, PreloaderPercent, PreloaderTracks } from './Preloader';


const API_URL = process.env.REACT_APP_API_URL;

export function PlayerVideoSimple({ context = {}, children }) {
  // const context = useContext(Context);
  const videoRef = useRef(null);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [videoBlobUrl, setVideoBlobUrl] = useState(null);

  // const videoUrl = 'https://drive.google.com/uc?export=view&id=1kjOUfLt90mzQsgOluGQbmygKzHkpJ1fA';
  const videoUrl = context.state.videoUrl ? `${API_URL}/download_rus?file_name=${context.state.mediaId}/${context.state.videoUrl}` : '';




  // Play or pause the video based on the 'playing' prop
  useEffect(() => {
    if (videoRef.current) {
      if (context.state.playing) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [context.state.playing]);

  // Set the start time of the video based on the 'startTime' prop
  useEffect(() => {
    let startTime = context.state.playOriginalStart;
    if (startTime != null && videoRef.current) {
      videoRef.current.currentTime = startTime / 1000;
    }
  }, [context.state.playOriginalStart]);

  return (
    <>
      <div className="c">
        <div className="x_player_video0">



          {children}



          {Boolean(context.state.videoUrl)
            && Boolean(context.state['mp3'])
            && context.state.video
            && <div className="x_player_video_sett0">

              <div className={`x_player_video_set_1 ${context.state.video === 'sm' ? 'active' : ''}`}
                onClick={() => context.handles.setVideo('sm')}
              >
                <div className="ico">
                  <div>crop_7_5</div>
                </div>
              </div>

              <div className={`x_player_video_set_1 ${context.state.video === 'md' ? 'active' : ''}`}
                onClick={() => context.handles.setVideo('md')}
              >
                <div className="ico">
                  <div>crop_5_4</div>
                </div>
              </div>

              <div className={`x_player_video_set_1 ${context.state.video === 'full' ? 'active' : ''}`}
                onClick={() => context.handles.setVideo('full')}
              >
                <div className="ico">
                  <div>fullscreen</div>
                </div>
              </div>

            </div>}

          <Video
            videoRef={videoRef}
            videoUrl={videoUrl}
            videoMode={context.state.video}
            className={`x_player_video x_player_video--${context.state.video}`}
          />


        </div>
      </div>
    </>
  );
}



export const Video = ({ videoRef = '', videoUrl = '', videoMode = true, className = '' }) => {
  return (<>
    {Boolean(videoUrl) && <div className={` _fv _fcc _f100`}
      style={{ zIndex: 111, overflow: 'hidden', position: 'absolute' }}
    >
            <PreloaderGif width={44} height={44} />
    </div >}


          {
      Boolean(videoMode) &&
            Boolean(videoUrl) && <video
            style={{ zIndex: 2 }}
            ref={videoRef}
            // controls
          className={className}
            muted
          >
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>}

  </>)
}