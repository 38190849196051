import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import { Context } from '../context';
import { PlayerTranslated } from './PlayerTranslated';
import { Flex, Ico, Overlay } from './Uikit';

const API_URL = process.env.REACT_APP_API_URL;

const areEqual = (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps);

export const DraggingFragment = React.memo(({
  id,
  ind,
  oneTrack,
  marginLeft,
  marginRight,
  handlePlayNext,
  start,
  length,
  trimmed_start,
  end_rus_milli,
  start_rus_milli,
  totalLength,
  tracks,
  onUpdatePosition,
  onUpdateLength,
  timeoutRef,
  openEditor,
  draggingTrack,
  subtitles,
}, areEqual) => {
  const context = useContext(Context);
  const zoomLevel = 100 - context.state['zoomLevel'];
  const [position, setPosition] = useState(start);
  const [lengthPosition, setLengthPosition] = useState(length);
  const [isDragging, setIsDragging] = useState(false);
  const [isLengthing, setIsLengthing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [lengthX, setLengthX] = useState(0);

  const [dragStart, setDragStart] = useState(false);
  const { trimmed } = oneTrack;

  // useEffect(() => {
  //   context.handles.setIsDragging(isDragging)
  // }, [isDragging])

  useEffect(() => {
    if (length !== lengthPosition) {
      setLengthPosition(length)
    }
  }, [length])

  const clickHandler = () => {
    console.log('CLICKED = ', oneTrack);
    // const scrubberWidth = context.state.zoomFragment[1] - context.state.zoomFragment[0];
    context.handles.setPlayingFragment(oneTrack['id']);
    // const newStart = oneTrack['start'];
    const newStart = position;
    // console.log('newStart = ', newStart);
    context.handles.setPlayStart(newStart);
    context.handles.setPlayOriginalStart(oneTrack['original_start']);
    context.handles.setPlaying(true);
    context.handles['setZoomFragment']([startX / zoomLevel, (startX + context.state.scrubberWidth) / zoomLevel]);
    // console.log('oneTrack[start] = ', startX);
    context.handles.setPlaybackTime(oneTrack['start'])
    // console.log(`Playing Fragment ID set to: ${oneTrack['id']}`);
    context.handles['setPlayingFragmentNext']();
    // if (context.state.playingFragment === oneTrack['id'] && !context.state['playing']) {
    // context.handles.setPlaying(true)
    // }
    // if (context.state.playingFragment !== oneTrack['id'] && !context.state['playing']) {
    //   context.handles.setPlaying(false)
    // }
  }




  const getTrackClass = (trackId, tracks) => {
    const { playingFragment, playingFragmentNext, changed } = context.state;
    // const playingFragment = id;

    let className = "x_track_fragment";
    if (changed.includes(trackId)) className += " changed";
    if (trackId === playingFragment) className += " active";
    if (trimmed) className += " trimmed";
    if (context.state.changedRecently.includes(id)) className += " changed_recently";

    // Determine if this track is the next one to play and is currently in the delay period
    const isNextTrackInDelay = trackId === playingFragmentNext && timeoutRef.current;

    if (isNextTrackInDelay || trackId === context.handles.getNextTrackId(playingFragment, tracks)) {
      className += " next";
    }

    return className;
  };



  const dragHandleRef = useRef(null);
  const dragHandleRefStart = useRef(null);
  const lengthHandleRef = useRef(null);

  const handleClick = () => {
    if (!isDragging) {
      clickHandler(oneTrack)
    }
  }

  const handlePositionMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation(); // Add this line to stop event propagation

    if (dragHandleRef.current && dragHandleRef.current.contains(e.target)) {
      setIsDragging(true);
      setStartX(e.clientX);
    }

    if (dragHandleRefStart.current && dragHandleRefStart.current.contains(e.target)) {
      setIsDragging(true);
      setDragStart(true)
      setStartX(e.clientX);
    }

    if (
      (dragHandleRef.current && !dragHandleRef.current.contains(e.target)) &&
      (lengthHandleRef.current ? !lengthHandleRef.current.contains(e.target) : true)) {
      clickHandler();
    }
  };
  const handleLengthMouseDown = (e) => {
    e.preventDefault();
    if (lengthHandleRef.current && lengthHandleRef.current.contains(e.target)) {
      setIsLengthing(true);
      setLengthX(e.clientX);
    }
  }

  const getMaxSpace = () => {
    const currentIndex = tracks.findIndex(t => t.id === id);
    const isLastTrack = currentIndex === tracks.length - 1;

    let min;
    let max;

    if (!subtitles) {
      if (tracks[currentIndex - 1]) {
        const trackLength = tracks[currentIndex - 1].length ?? 0;
        min = currentIndex > 0 ? tracks[currentIndex - 1]['start'] + trackLength : 0;
      } else {
        min = 0;
      }
      max = isLastTrack ? context.state['durationFull'] - length : tracks[currentIndex + 1]['start'] - length;
    } else {
      // let length = track['end_rus_milli'] - track['start_rus_milli']
      // min = currentIndex > 0 ? tracks[currentIndex - 1]['end_rus_milli'] : 0;
      // console.log('min = ', min);
      // max = isLastTrack ? context.state['durationFull'] - length : tracks[currentIndex + 1]['start_rus_milli'] - length;
      min = 0;
      max = context.state['durationFull'] - length;
    }

    // Correction for the max calculation for the last track
    if (isLastTrack) {
      max = context.state['durationFull'] - length;
    }

    // console.log(`Track ID: ${id}, Min: ${min}, Max: ${max}, Is Last Track: ${isLastTrack}`);
    return { min, max };
  };

  const getMaxLength = () => {
    const currentIndex = tracks.findIndex(t => t.id === id);
    const isLastTrack = currentIndex === tracks.length - 1;

    let min = 1000;
    let what = subtitles ? 'start_rus_milli' : 'start'
    let max = isLastTrack ? context.state['durationFull'] : tracks[currentIndex + 1][what];

    // Correction for the max calculation for the last track
    if (isLastTrack) {
      max = context.state['durationFull'];
    }

    // console.log(`Track ID: ${id}, Min: ${min}, Max: ${max}, Is Last Track: ${isLastTrack}`);
    return { min, max };
  };



  const handleMouseMove = useCallback((e) => {
    e.preventDefault();
    if (isDragging) {
      const { min, max } = getMaxSpace();
      const deltaX = e.clientX - startX;
      const newPosition = position + deltaX * zoomLevel;

      if (newPosition >= min && newPosition <= max) {
        setPosition(newPosition);

        if (dragStart) {
          const newLengthPosition = lengthPosition - (deltaX * zoomLevel);
          if (newLengthPosition >= min && newLengthPosition <= max) {
            setLengthPosition(newLengthPosition)
          }
        }

        setStartX(e.clientX);
        context.handles.addNewChange(id, 'changed');
      }
    }
    if (isLengthing) {
      const { min, max } = getMaxLength();
      const deltaX = e.clientX - lengthX;
      const newLengthPosition = lengthPosition + deltaX * zoomLevel;
      setLengthPosition(newLengthPosition);
      setLengthX(e.clientX);
      context.handles.addNewChange(id, 'changed');
    }
  }, [isDragging, isLengthing, position, lengthPosition, startX, lengthX, zoomLevel, dragStart, id, getMaxSpace, getMaxLength]);


  useEffect(() => {

    if (subtitles &&
      !isDragging && startX && startX !== context.state['translationData'][ind]['start']) {
      onUpdatePosition(id, position, subtitles, dragStart);
      onUpdateLength(id, lengthPosition, subtitles, dragStart);
    }

    if (!subtitles &&
      !isDragging && startX && startX !== context.state['translationData'][ind]['start_rus_milli']) {
      onUpdatePosition(id, position, subtitles, dragStart);
    }
  }, [isDragging])

  useEffect(() => {
    if (subtitles &&
      !isLengthing && lengthX && lengthX !== context.state['translationData'][ind]['length']) {
      onUpdateLength(id, lengthPosition, subtitles, dragStart);
    }
    if (!subtitles &&
      !isLengthing && lengthX && lengthX !== context.state['translationData'][ind]['end_rus_milli'] - context.state['translationData'][ind]['start_rus_milli']) {
      onUpdateLength(id, lengthPosition, subtitles, dragStart);
    }
  }, [isLengthing])



  const handleMouseUp = (e) => {
    e.preventDefault();
    setIsDragging(false);
    setIsLengthing(false);
    setDragStart(false);
    //TODO: play on drag
    // context.handles['setPlayingFragment'](id);
    // if (timeoutRef.current) {
    //   clearTimeout(timeoutRef.current);
    // }
  };

  useEffect(() => {
    if (draggingTrack.current) {
      draggingTrack.current.addEventListener('pointermove', handleMouseMove);
      draggingTrack.current.addEventListener('pointerup', handleMouseUp);
    }
    return () => {
      if (draggingTrack.current) {
        draggingTrack.current.removeEventListener('pointermove', handleMouseMove);
        draggingTrack.current.removeEventListener('pointerup', handleMouseUp);
      }
    };
  }, [isDragging, isLengthing, handleMouseMove, handleMouseUp]);

  useEffect(() => {
    // console.log('oneTrack[captions] = ', oneTrack['captions']);
  }, [oneTrack])

  // console.log('oneTrack[\'id\'] = ', oneTrack['id']);
  // console.log('oneTrack[\'file\'] = ', oneTrack['file']);
  // console.log('oneTrack[\'captions\'] = ', oneTrack['captions']);

  const handleTouchStart = (e) => {
    if (dragHandleRef.current && dragHandleRef.current.contains(e.target)) {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
      setStartX(e.touches[0].clientX);
    } else if (dragHandleRefStart.current && dragHandleRefStart.current.contains(e.target)) {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
      setDragStart(true);
      setStartX(e.touches[0].clientX);
    }
    // If the touch is outside the drag handles, do nothing, allowing parent scroll
  };

  const handleTouchMove = useCallback((e) => {
    if (isDragging) {
      e.preventDefault();
      e.stopPropagation();
      const touch = e.touches[0];
      const { min, max } = getMaxSpace();
      const deltaX = touch.clientX - startX;
      const newPosition = position + deltaX * zoomLevel;

      if (newPosition >= min && newPosition <= max) {
        setPosition(newPosition);

        if (dragStart) {
          const newLengthPosition = lengthPosition - (deltaX * zoomLevel);
          if (newLengthPosition >= min && newLengthPosition <= max) {
            setLengthPosition(newLengthPosition);
          }
        }

        setStartX(touch.clientX);
        context.handles.addNewChange(id, 'changed');
      }
    }
    // If not dragging, do nothing, allowing parent scroll
  }, [isDragging, position, lengthPosition, startX, zoomLevel, dragStart, id, getMaxSpace]);

  const handleTouchEnd = (e) => {
    if (isDragging) {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      setDragStart(false);
    }
  };

  useEffect(() => {
    if (draggingTrack.current) {
      draggingTrack.current.addEventListener('touchstart', handleTouchStart, { passive: false });
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
      document.addEventListener('touchend', handleTouchEnd);
    }
    return () => {
      if (draggingTrack.current) {
        draggingTrack.current.removeEventListener('touchstart', handleTouchStart);
      }
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handleTouchMove]);

  if (!oneTrack['original_length']) {
    return null;
  }

  return (

    <div

      style={{
        position: 'absolute',
        left: `${position / zoomLevel}px`,
        width: `${lengthPosition / zoomLevel}px`,
        cursor: 'pointer',
        // boxShadow: `${!subtitles && trimmed ? `${trimmed / zoomLevel}px 0 0 0px rgba(255, 0, 0, 0.7)` : 'none'}`,
        boxShadow: `${!subtitles && trimmed ? `${trimmed / zoomLevel}px 0 0 0px rgba(255, 0, 0, 0.7)` : `${trimmed_start ? `inset ${trimmed_start / zoomLevel}px 0 0 0px rgba(255, 0, 0, 0.7)` : ''}`}`,
        zIndex: `${trimmed ? 1 : 'inherit'}`,
        // background: trimmed_start ? 'red' : 'inherit',
        // overflowX: 'hidden',
        // overflowY: 'auto',
        // height: subtitles ? '1em' : '4em',
        // Remove touchAction: 'none' from here
      }}
      className={getTrackClass(oneTrack.id, tracks)}
    // key={`${context.state['mediaId']}_${oneTrack['id']}`}
      // draggable="true"
      // onClick={handleClick}
    >





      <div className="x_track_start" />

      <div onMouseDown={handlePositionMouseDown}>

        {!isDragging && (subtitles || (!Boolean(oneTrack['file'])) || Boolean(oneTrack['add_background'])) && <div className={isLengthing ? "x_track_length0 dragging" : "x_track_length0"}>
          <div className="x_track_length" ref={lengthHandleRef}
            onMouseDown={handleLengthMouseDown}
          >
            {!isLengthing && <div className="ico">drag_indicator</div>}
            {isLengthing && <svg
              className='x_track_drag-icon'
              width={20} height={14} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7 1.2071V12.7929C7 13.2383 6.46143 13.4614 6.14645 13.1464L0.707108 7.7071C0.316584 7.31658 0.316585 6.68341 0.707108 6.29289L6.14645 0.853549C6.46143 0.538567 7 0.761651 7 1.2071ZM13 1.2071V12.7929C13 13.2383 13.5386 13.4614 13.8536 13.1464L19.2929 7.7071C19.6834 7.31658 19.6834 6.68341 19.2929 6.29289L13.8536 0.853549C13.5386 0.538567 13 0.761651 13 1.2071Z" fill="white" />
            </svg>}
          </div>
        </div>}

        {(subtitles || !Boolean(oneTrack['file']))
          && <div className={isLengthing ? "x_track_length0 x_track_length0--start dragging" : "x_track_length0 x_track_length0--start"}>
          <div className="x_track_length x_track_length--start"
            ref={dragHandleRefStart}
          >
            {!isLengthing && <div className="ico">drag_indicator</div>}
            {isLengthing && <svg
              className='x_track_drag-icon'
              width={20} height={14} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7 1.2071V12.7929C7 13.2383 6.46143 13.4614 6.14645 13.1464L0.707108 7.7071C0.316584 7.31658 0.316585 6.68341 0.707108 6.29289L6.14645 0.853549C6.46143 0.538567 7 0.761651 7 1.2071ZM13 1.2071V12.7929C13 13.2383 13.5386 13.4614 13.8536 13.1464L19.2929 7.7071C19.6834 7.31658 19.6834 6.68341 19.2929 6.29289L13.8536 0.853549C13.5386 0.538567 13 0.761651 13 1.2071Z" fill="white" />
            </svg>}
          </div>
        </div>}

        {(!Boolean(oneTrack['file']) && !subtitles) && <>
          <Overlay style={{ color: 'white', fontSize: '0.6em' }}>
            <Flex gap='.2em'>
              <Ico>music_off</Ico>Нет файла
            </Flex>
          </Overlay>
        </>}


        {context.state.waitingCut.includes(oneTrack.original_id) && <div className="p_b_preloader"><img src="https://assets-global.website-files.com/651f0451acd1cf1eb674532d/657223dc690f13a86f06e953_preloader.gif" loading="lazy" className="preloadergif" /></div>}

        <div style={{
          overflow: 'hidden',
          width: lengthPosition / zoomLevel,
          opacity: context.state.waitingCut.includes(oneTrack.original_id) ? 0.3 : 1,
        }} >
          {!subtitles && Boolean(oneTrack['file']) && <PlayerTranslated
            id={id}
            ind={ind}
            trimmed={trimmed}
            length={length}
            width={oneTrack['original_length'] / zoomLevel}
            end_rus_milli={end_rus_milli}
            start_rus_milli={start_rus_milli}
            // handleClick={() => {
            //   setLeftPadding(Math.floor(marginLeft / zoomLevel + 40))
            // }}
            handlePlayNext={handlePlayNext}
            // handlePlayNext={() => {
            //   handlePlayNext(ind)
            // }}
            mp3={`${API_URL}/${oneTrack['file']}`}

        >
        </PlayerTranslated>}
        </div>

        {!isLengthing && <div className="x_track_drag0" ref={dragHandleRef}>
          <div className="x_track_drag-handle">
            <svg
              className='x_track_drag-icon'
              width={20} height={14} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7 1.2071V12.7929C7 13.2383 6.46143 13.4614 6.14645 13.1464L0.707108 7.7071C0.316584 7.31658 0.316585 6.68341 0.707108 6.29289L6.14645 0.853549C6.46143 0.538567 7 0.761651 7 1.2071ZM13 1.2071V12.7929C13 13.2383 13.5386 13.4614 13.8536 13.1464L19.2929 7.7071C19.6834 7.31658 19.6834 6.68341 19.2929 6.29289L13.8536 0.853549C13.5386 0.538567 13 0.761651 13 1.2071Z" fill="white" />
            </svg>
          </div>
          {/* <div className="x_track_fragment_drag"
          style={{
            // left: 0,
            // right: 0,
            left: Math.floor(marginLeft / zoomLevel * -1),
            right: Math.floor(marginRight / zoomLevel * -1),
          }}
        /> */}
          {/* </div> */}
        </div>}
      </div>

      {context.state.video !== 'full' && <div className="x_track_cc"
        onClick={() => {
          openEditor(oneTrack)
        }}
      >

        {/* <p>
          Trimmed: {trimmed}
        </p> */}
        {/* <p>
          Start: {start}
        </p> */}
        {/* <p>
          length: {length}
        </p> */}
        {/* <p>
          End Rus: {end_rus_milli}
        </p> */}

        <span>
          {subtitles && <>
            <div className="x_track_cc x_track_cc--v"
              style={{ marginTop: `${ind * 3}em` }}
            >{oneTrack['captions_rus']}</div>
            {/* <p>
              Min: {getMaxSpace()['min']}
              <br />Max: {getMaxSpace()['max']}
            </p> */}
            {/* StartRus: {start_rus_milli} */}
            {/* <br />EndRus: {end_rus_milli} */}
          </>}{!subtitles && oneTrack['captions']}
        </span>
        {trimmed_start && <span style={{ color: 'red' }}>⚠️ Начало фрагмента за пределами</span>}

        <div className="x_track_edit">
          <div className="ico inact">
            edit
          </div>
        </div>

      </div>}
    </div>


  );
})
