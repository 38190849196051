import styled from "styled-components";

export const TracksContainerCS = styled.div`
  display: flex;
`;

export const TrackAllOrder0SC = styled.div`
  z-index: 2;
  grid-column-gap: 0.2em;
  grid-row-gap: 0.2em;
  background-color: var(--blue-dark);
  flex-flow: column;
  display: flex;
  position: sticky;
  left: 0;
`;
