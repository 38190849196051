import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { Input, Select, Tooltip } from 'antd';
import { Button, Ico } from './Uikit';
import { Context } from '../context';
import { PreloaderGif } from './Preloader';
import { formatTime } from './utils';
import { PopoverH, TooltipH } from './UiKit/PopoverH';
import ReactWaves from '@dschoon/react-waves';
import { ButtonH } from './UiKit/ButtonH';

const { TextArea } = Input;

const API_URL = process.env.REACT_APP_API_URL;

export function TranslateLine({ track = {}, editing, setEditing }) {
  const { state, handles } = useContext(Context);
  const [voice, setVoice] = useState('male1');

  const [captions, setCaptions] = useState(track.captions);
  const [captionsRus, setCaptionsRus] = useState(track.captions_rus);

  const resetCaptions = () => {
    setCaptions(track.captions)
  }
  const saveCaptions = (e) => {
    e.stopPropagation();
    handles.saveCaptionsTranslate(track, captions);
    setEditing('');
  }


  const handleResetAndToggle = (e) => {
    e.stopPropagation()
    if (typeof state.playStart === 'number' && state.playingFragment === track.id) {
      // wavesurfer.seekTo(0);
    }
  };

  const handleGenerate = useCallback(async () => {
    console.log('track = ', track);


    try {
      const data = await handles.audioSynthesize(track.original_id, state.folder, state.mediaId);
      // Handle the response data as needed
    } catch (error) {
      // Handle the error (e.g., show an error message to the user)
    }
  }, [track.original_id, state.folder, state.mediaId, handles.audioSynthesize]);

  return (
    <div
      onClick={() => setEditing(track.id)}
      className={`xt_line ${editing === track.id ? 'active' : ''}`}
    >
      <div className="xt_time">

        <div className="xt_fragment_play0" onClick={handleResetAndToggle}>
          <div className="x_player_control_play" onClick={(e) => {
            handles.setTranslateLineOffset(track['start'] + e.pageY);
            setEditing(track.id)
            if (state['mp3']) {

              // handles.setPlaybackTime(track['start'])
              handles.setPlayStart(track['start'])
              // handles.setPlayOriginalStart(track['original_start']);
              if (state['playing'] && state.playingFragment === track.id) {
                handles.setPlaying(false)
                handles.setPlayingFragment()
              }

              if (!state['playing'] || state.playingFragment !== track.id) {
                handles.setPlaying(true)
                handles.setPlayingFragment(track.id)
              // handles.setPlaybackTime(track['start'])
              // handles.setPlayOriginalStart(track['original_start']);
              }
              // handles.setPlaying(!state['playing'])
            }
          }
          }>
            {state['mp3'] && <div className="ico ico--40">
              {state.playingFragment === track.id && <>
                {!state['playing'] && <div>play_arrow</div>}
                {state['playing'] && <div>pause</div>}
              </>}

              {state.playingFragment !== track.id && <>
                <div>play_arrow</div>
              </>}


            </div>}
            {!state['mp3'] && <PreloaderGif />}
          </div>
        </div>

        <div className="xt_time_1">{formatTime(track.start_rus)}</div>
        <div className="xt_time_1">–</div>
        <div className="xt_time_1">{formatTime(track.end_rus)}</div>
      </div>

      <div className="xt_text">{captionsRus}</div>

      <div className={`xt_text ${state.changedRecently.includes(track.id) ? 'xt_text--justchanged' : ''} ${state.changedTranslation.includes(track.id) ? 'xt_text--changed' : ''}`}>
        {<div contentEditable
          suppressContentEditableWarning={true}
          onBlur={(e) => setCaptions(e.target.innerHTML)}>{captions}
        </div>}
        {editing === track.id && <div className="xt_textarea_actions">
          <div className="x_save  x_save--cancel" onClick={resetCaptions}><Ico size={'1em'}>undo</Ico></div>
          <div className="x_save" onClick={saveCaptions}><Ico size={'1em'}>check</Ico></div>
        </div>}
      </div>

      <div className="xt_trans">
        {!track.file && <div className="xt_fragment_play0">
          <PopoverH
            placement='bottomRight'
            trigger="click"
            title={<>
              <div className="_fv" style={{ padding: '1em' }}>
                <b>Generate translation</b>
                <div
                // onClick={(e) => e.stopPropagation()}
                >
                  <div className="_fm">
                    <Select
                      value={voice}
                      onChange={(value) => {
                        console.log('value = ', value)
                        setVoice(value)
                      }}
                      style={{ width: '180px' }}
                      options={[{
                        value: 'male1',
                        label: 'Олег Торсунов',
                      },
                      {
                        value: 'male2',
                        label: 'Аудитория: Мужской',
                      },
                      {
                        value: 'female',
                        label: 'Аудитория: Женский',
                      },
                      ]}
                    /></div>
                </div>
                <ButtonH onClick={handleGenerate}>Сгенерировать перевод</ButtonH>
              </div>
            </>}>
            <div className="x_player_control_play">
              <div className="ico ico--40"><div>maps_ugc</div></div>
            </div>
          </PopoverH>
        </div>}
        {track.file && <div className="xt_fragment_play0">
          <div className="x_player_control_play">
            <div className="ico ico--40"><div>campaign</div></div>
          </div>
        </div>}
      </div>

      <PlayerLine track={track} editing={editing} setEditing={setEditing} />

      {editing === track.id && <div className="xt_line_bg active" />}
    </div>
  );
}



const PlayerLine = ({ track, editing, setEditing }) => {
  const context = useContext(Context);
  const wavesRef = useRef(null);
  const zoomLevel = 100 - context.state['zoomLevel'];

  const [wavesurfer, setWavesurfer] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [pos, setPos] = useState(0);

  const options = {
    barHeight: 2,
    barRadius: 3,
    cursorWidth: 0,
    height: 60,
    hideScrollbar: true,
    progressColor: '#ff7606',
    responsive: true,
    waveColor: '#D1D6DA',
    interact: false,
    dragSelection: false
  };

  const onLoading = ({ wavesurfer }) => {
    setWavesurfer(wavesurfer);
  };

  const onPosChange = useCallback((newPos, wavesurfer) => {
    if (newPos !== pos) {
      setPos(newPos);
      setWavesurfer(wavesurfer);
    }
  }, [pos]);

  const handleResetAndToggle = () => {
    if (typeof context.state.playStart === 'number' && context.state.playingFragment === track.id) {
      wavesurfer.seekTo(0);
    }
  };

  useEffect(() => {
    setPos(0);

    if (wavesurfer && typeof context.state.playStart === 'number') {
      wavesurfer.seekTo(0);
    }

    return () => {
      // Cleanup if needed
    };
  }, [context.state['playingFragment'], context.state.playStart, track.id, wavesurfer]);

  const [audioUrl, setAudioUrl] = useState(`${API_URL}/${track.file}?cache=${Date.now()}`);


  const recacheAudio = useCallback(() => {
    setAudioUrl(`${track.file}?cache=${Date.now()}`);
  }, [track.file]);

  useEffect(() => {
    recacheAudio();
  }, [track.end_rus_milli, recacheAudio]);

  return (
    <div
      style={{
        paddingTop: '0.26em',
        width: '100%',
        position: 'absolute',
      }}
      onClick={handleResetAndToggle}
    >
      <div style={{
        top: 0, bottom: 0, left: 0, right: 0, zIndex: 0, position: 'absolute'
      }} />

      <ReactWaves
        key={audioUrl}
        ref={wavesRef}
        audioFile={audioUrl}
        className={'react-waves'}
        options={{ ...options }}
        volume={context.state['volumeTranslated'] / 100}
        zoom={(100 - context.state.zoomLevel) / 100}
        pos={pos}
        playing={context.state['playingFragment'] === track.id}
        onLoading={onLoading}
        onFinish={() => {
          // Handle finish if needed
        }}
      />
    </div>
  );
};