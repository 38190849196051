import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context";
import { getSpacesApplied } from "../../utils";
import { segmentsToKeep } from "../../api";
import { ButtonH } from "../UiKit/ButtonH";
import { HorizontalSlider } from '../HorizontalSlider';
import { Ico } from "../Uikit";
import { TooltipH } from '../UiKit/TooltipH';
import { PopoverH } from '../UiKit/PopoverH';
import { Switch } from "antd";

export function VoicingSettings(props) {

  const context = useContext(Context);
  const voicingSpaces = context.state['voicingSpaces'];
  const voicingSpacesDeleted = context.state['voicingSpacesDeleted'];

  const [voicingSpacesMinimum, setVoicingSpacesMinimum] = useState(context.state['voicingSpacesMinimum']);
  const [voicingSpacesMinimumOffset, setVoicingSpacesMinimumOffset] = useState(context.state['voicingSpacesMinimumOffset']);

  const saveSpacesMinimum = (val) => {
    // setVoicingSpacesMinimum(val);
    localStorage.setItem('voicingSpacesMinimum', val);
    context.handles['setVoicingSpacesMinimum'](val);
  }

  const saveSpacesMinimumOffset = (val) => {
    localStorage.setItem('voicingSpacesMinimumOffset', val);
    context.handles['setVoicingSpacesMinimumOffset'](val);
  }

  useEffect(() => {
    console.log('voicingSpacesMinimum = ', voicingSpacesMinimum);
    console.log('context.state[voicingSpacesMinimum] = ', context.state['voicingSpacesMinimum']);
    setVoicingSpacesMinimum(context.state['voicingSpacesMinimum']);
  }, [context.state['voicingSpacesMinimum']]);

  useEffect(() => {
    setVoicingSpacesMinimumOffset(context.state['voicingSpacesMinimumOffset']);
  }, [context.state['voicingSpacesMinimumOffset']]);

  const spacesApplied = getSpacesApplied(voicingSpaces, voicingSpacesDeleted);

  return (
    <>
      <PopoverH
        placement='top'
        title={
          <div className="_fv _fgap2 _f100" >


            <div className="_f _f100">
              <Switch
                defaultChecked={context.state['voicingSpacesOn']}
                onChange={(val) => context.handles['setVoicingSpacesOn'](val)}
              /> Включить удаление пустот
            </div>

            <ButtonH
              ghost={!spacesApplied.length}
              inactive={!spacesApplied.length}
              disabled={!spacesApplied.length}
              onClick={() => segmentsToKeep(context.state.mediaId, spacesApplied)}>
              Вырезать пустоты
            </ButtonH>

            <div className="_fv _f100 _r04 _pd1" style={{ backgroundColor: "rgb(233,233,233)" }}>
              <div className="">
                Минимальный промежуток: {(voicingSpacesMinimum / 1000).toFixed(1)} сек
              </div>
              <div className="_f100">
                <div>0</div>
                <HorizontalSlider
                  value={voicingSpacesMinimum}
                  min={0}
                  max={30000}
                  width={200}
                  step={100}
                  onValueChange={(val) => setVoicingSpacesMinimum(val)}
                />
                <div>30 сек</div>
              </div>

              <div className="_f100 _fcc">
                <ButtonH
                  ghost={voicingSpacesMinimum === context.state['voicingSpacesMinimum']}
                  inactive={voicingSpacesMinimum === context.state['voicingSpacesMinimum']}
                  disabled={voicingSpacesMinimum === context.state['voicingSpacesMinimum']}
                  onClick={() => saveSpacesMinimum(voicingSpacesMinimum)}>
                  Применить
                </ButtonH>
                <ButtonH
                  style={{
                    opacity: voicingSpacesMinimum === context.state['voicingSpacesMinimum'] ? 0.4 : 1,
                  }}
                  inactive={voicingSpacesMinimum === context.state['voicingSpacesMinimum']}
                  disabled={voicingSpacesMinimum === context.state['voicingSpacesMinimum']}
                  ghost={true}
                  onClick={() => setVoicingSpacesMinimum(context.state['voicingSpacesMinimum'])}>
                  <Ico size={16}>undo</Ico>
                </ButtonH>

              </div>
            </div>


            <div className="_fv _f100 _r04 _pd1" style={{ backgroundColor: "rgb(233,233,233)" }}>
              <div className="">
                Отступы выреза тишины: {(voicingSpacesMinimumOffset / 1000).toFixed(1)} сек
              </div>
              <div className="_f100">
                <div>0</div>
                <HorizontalSlider
                  value={voicingSpacesMinimumOffset}
                  min={0}
                  max={3000}
                  width={200}
                  step={100}
                  onValueChange={(val) => setVoicingSpacesMinimumOffset(val)}
                />
                <div>3 сек</div>
              </div>

              <div className="_f100 _fcc">
                <ButtonH
                  ghost={voicingSpacesMinimumOffset === context.state['voicingSpacesMinimumOffset']}
                  inactive={voicingSpacesMinimumOffset === context.state['voicingSpacesMinimumOffset']}
                  disabled={voicingSpacesMinimumOffset === context.state['voicingSpacesMinimumOffset']}
                  onClick={() => saveSpacesMinimumOffset(voicingSpacesMinimumOffset)}>
                  Применить
                </ButtonH>
                <ButtonH
                  style={{
                    opacity: voicingSpacesMinimumOffset === context.state['voicingSpacesMinimumOffset'] ? 0.4 : 1,
                  }}
                  inactive={voicingSpacesMinimumOffset === context.state['voicingSpacesMinimumOffset']}
                  disabled={voicingSpacesMinimumOffset === context.state['voicingSpacesMinimumOffset']}
                  ghost={true}
                  onClick={() => setVoicingSpacesMinimumOffset(context.state['voicingSpacesMinimumOffset'])}>
                  <Ico size={16}>undo</Ico>
                </ButtonH>

              </div>
            </div>




            {/* <PopoverH
              placement='top'
              trigger="click"
              title={<>
                <div className="_fv _fcc">
                  <div>Нажатие удалит все вручную добавленные изменения</div>
                  <ButtonH sm ghost red>Применить</ButtonH>
                </div>
              </>}>
              <ButtonH sm ghost red
                onClick={() => context.handles.setVoicingSpaces([])}
              >Перегенерировать...</ButtonH>
            </PopoverH> */}

          </div>
        }>



        <div className="_f _fm">
          Удаление пустот:
          <ButtonH sm ghost ><Ico size={16}>settings</Ico></ButtonH>
        </div>


      </PopoverH>
    </>
  )
}
