import React, { useContext, useMemo, useEffect } from 'react';
import { Context } from '../../context';
import { getSpacesApplied } from '../../utils';
import { SpaceControls } from './SpaceControls';

export function VoicingSilence(props) {
  const context = useContext(Context);

  const voicingSpaces = context.state['voicingSpaces'];
  const voicingSpacesDeleted = context.state['voicingSpacesDeleted'];
  const voicingSpacesMinimum = context.state['voicingSpacesMinimum'];
  const voicingSpacesMinimumOffset = context.state['voicingSpacesMinimumOffset'];

  const spaces = useMemo(() => {
    const translationData = context.state['translationData'] || [];
    console.log('tracks = ', translationData);
    return translationData
      .map((track, index) => {
        const previousTrackEnd =
          index === 0
            ? 0 // For the first track, take 0
            : translationData[index - 1].start +
              translationData[index - 1].length +
              voicingSpacesMinimumOffset; // End of previous track

        const nextTrackStart =
          index < translationData.length
            ? track.new_start - voicingSpacesMinimumOffset // Start of next track
            : undefined; // No next track for the last one

        return {
          start: previousTrackEnd,
          end: nextTrackStart,
          nextTrackId:
            index < translationData.length
              ? track.id
              : undefined, // ID of the current track
        };
      })
      .filter((space) => space.end !== undefined) // Filter out the last track
      .filter((space) => {
        const length = space.end - space.start;
        return length >= voicingSpacesMinimum;
      })
      .filter((space) => {
        return space.end - space.start > 0;
      });
  }, [
    context.state['translationData'],
    voicingSpacesMinimum,
    voicingSpacesMinimumOffset,
  ]);

  useEffect(() => {
    context.handles.setVoicingSpaces(spaces);
  }, [spaces]);

  /*useEffect(() => {
    if (voicingSpaces.length === 0 && spacesAll.length > 0) {
      context.handles.setVoicingSpaces(spacesAll);
    }
  }, [voicingSpaces, spacesAll]);*/

  return (
    <>
      {context.state['voicingSpacesOn'] && (
        <div
          style={{
            position: 'absolute',
            zIndex: 100,
            width: '100%',
            height: '100%',
            border: '3px solid red',
          }}
        >
          {spaces.map((space, index) => (
            <SpaceControls
                space={space}
                setDeleted={context.handles.setVoicingSpacesDeleted}
                deleted={voicingSpacesDeleted}
                key={space.nextTrackId || index}
                context={context}
          />
          ))}
        </div>
      )}
    </>
  );
}
