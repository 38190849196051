import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { HorizontalSlider } from './HorizontalSlider';
import { VolumeCross } from './VolumeCross';
import { Ico } from './Uikit';
import { VoicingSettings } from './Voicing/VoicingSettings';

export function Footer({ context, view = ['audioVolume', 'zoomLevel', 'voicingSpacesMinimum'] }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showVoicing = queryParams.get('voicing');
  // const context = useContext(Context);
  return (
    <>
      <div className="x_flex-v"
        style={{
          backgroundColor: '#ccc',
        }}
      >

        <div className="x_flex-h"
          style={{
            fontWeight: 600,
            padding: '1em',
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: '2em',
          }}
        >

          {view.includes('audioVolume') && <VolumeCross />}


          {view.includes('zoomLevel') && <div className="x_flex-h">
            <Ico style={{ fontSize: '1.6em' }}>zoom_out</Ico>
            <HorizontalSlider
              value={context.state['zoomLevel']}
              min={10}
              max={90}
              width={200}
              // onValueChange={(val) =>
              //   Math.floor(context.handles['setActiveSegmentLength'](val + 1) / 10) * 10
              // }
              onValueChange={(val) => context.handles['setZoomLevel'](val)}
            />
            <Ico style={{ fontSize: '1.6em' }}>zoom_in</Ico>
          </div>}

          {showVoicing && view.includes('voicingSpacesMinimum') &&
            <VoicingSettings />
          }


        </div>





      </div>

    </>
  )
}