import React, { useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Switch } from "antd";
import { VideosContext } from "../../../../../providers/VideosProvider/videosProvider";

export function VideosHead({ playerRef }) {
  const context = useContext(VideosContext);

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="_f" style={{ position: "absolute", top: "1em", left: "3em", zIndex: 25 }}>
      <VideosPlay enter={true} context={context} playerRef={playerRef} />
    </div>
  );
}

export function VideosPlay({ enter = false, defaultAction = true, playerRef }) {
  const context = useContext(VideosContext);

  useEffect(() => {
    if (enter) {
      const handleKeyPress = (e) => {
        if (e.key === " " && !context.state.pop) {
          // Spacebar key
          e.preventDefault();
          if (!context.state.playing) {
            playerRef.current.play();
          } else {
            playerRef.current.pause();
          }
          // context.handles.setPlayingFragment()
        }
      };
      document.addEventListener("keydown", handleKeyPress);

      // Clean up the event listener when the component is unmounted
      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [enter, context]);

  return (
    <>
      <div
        className="x_player_control_play"
        onClick={() => {
          if (!context.state.playing) {
            playerRef.current.play();
          } else {
            playerRef.current.pause();
          }
        }}
      >
        <div className="ico ico--40">
          {!context["state"]["playing"] && <div>play_arrow</div>}
          {context["state"]["playing"] && <div>pause</div>}
        </div>
      </div>

      <div
        className="x_player_control_play"
        onClick={() => {
          context.handles.setCurrentTime(0);
        }}
      >
        <div className="ico ico--40">
          <div>restart_alt</div>
        </div>
      </div>
    </>
  );
}
