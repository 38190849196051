import React, { Fragment, useEffect, useState, useContext, useRef, useParams } from 'react'
import { Link, withRouter, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components';
import { PlayerMain } from './PlayerMain';
import { ReelsContext } from '../contextReels';
import { ReelsPlayer } from './ReelsPlayer';

import english_reel from '../audio/english_reel.mp4'
import { ReelsHead } from './ReelsHead';
import { ReelTracking } from './ReelTracking';
import './add2.css';
import { Footer } from './Footer';
import { ReelsFragmentPlayer } from './ReelsFragmentPlayer';
import { ReelsFragnmentPlayerNew } from './ReelsFragnmentPlayerNew';
import './Reels/scrollbar-dark.css';
import { ReelsMenu } from './Reels/ReelsMenu';


export function LayoutComponent() {
  const context = useContext(ReelsContext);

  const playerRef = useRef(null);
  const fragmentPlayerRef = useRef(null);

  return (
    <div className="App b0">  

      <div className="c00">


        <ReelsMenu />

        <div className="c0">

      <ReelsHead playerRef={playerRef} />


          <div className="c c--video">

            {context.state.reelsFrame && <div className="x_reels0" style={{ opacity: 0.85 }}><div className="x_reels_lr" /><div className="x_reels_frame" /><div className="x_reels_lr" /></div>}

      {/* {context.fragments.map(one => <ReelsFragmentPlayer key={one.id} playingFragment={one.id} />)} */}


          <ReelsPlayer
            playerRef={playerRef}
            fragmentPlayerRef={fragmentPlayerRef}
          />
          <ReelsFragnmentPlayerNew
            playerRef={playerRef}
            fragmentPlayerRef={fragmentPlayerRef}
          />
          </div>

          <ReelTracking />

      {/* <Footer context={context} view={['zoomLevel']} /> */}

          <DebugReels />

        </div>
      </div>
    </div>
  )
}

export const LayoutReels = React.memo(LayoutComponent);


const DebugReels = ({ }) => {
  const context = useContext(ReelsContext);
  return (
    <div className="_f _t08">
      <div className="_f">
        Duration (ms):{context.videoDuration}
      </div>
      <div className="_f">
        Current time (ms):{context.state.currentTime}
      </div>
      <div className="_f">
        <div className="x_button x_button--ghost _t08" onClick={() => {
          localStorage.clear()
        }}>
          localStorage.clear()
        </div>

      </div>
    </div>
  )
}