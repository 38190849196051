import React, { useContext, useState, useRef, useEffect } from "react"
import { TooltipH } from "../UiKit/TooltipH"
import { Context } from "../../context";
import { PreloaderTracks, PreloaderPercent } from '../Preloader';
import { Video } from "../PlayerVideoSimple";
import { PlayerVideo } from '../PlayerVideo';

const API_URL = process.env.REACT_APP_API_URL;

export function SubtitlesPlayer({ context }) {

  // const context = useContext(Context);
  const videoUrl = context.state.videoUrl ? `${API_URL}/download_rus?file_name=${context.state.mediaId}/${context.state.videoUrl}` : '';
  const videoMode = context.state.video;


  const [left, setLeft] = useState(true);

  const expanded = context.state.video;
  const setExpanded = (mode = '') => context.handles.setVideo(mode)

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      if (context.state.playing) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [context.state.playing]);

  return (
    <>
      <div className={`x_sub_player0 ${expanded ? '' : 'x_sub_player0--collapsed'} ${left ? '' : 'x_sub_player0--right'}`}>
        <div className="x_sub_player_h">
          <div className="p_b_title">
            <div className="ico">
              <div>videocam</div>
            </div>
            <div>Видео</div>
          </div>
          <div className="x_sub_player_right">

            {expanded && <TooltipH title={`Переместить ${left ? 'вправо' : 'влево'}`}>
              <div className="x_button x_button--ghost"
                onClick={() => setLeft(prev => !prev)}
              >
                <div className="t_icon">
                  {left ? 'arrow_forward' : 'arrow_back'}
                </div>
              </div>
            </TooltipH>}

            {expanded && <div className="x_button x_button--ghost"
              onClick={() => setExpanded(false)}
            >
              <div>Скрыть</div>
            </div>}

            {!expanded && <div className="x_button x_button--ghost"
              onClick={() => setExpanded(true)}
            >
              <div>Развернуть</div>
            </div>}


          </div>
        </div>
        <div className="x_sub_player _fv _fcc">



          <PlayerVideo context={context}>
          </PlayerVideo>





        </div>
      </div>
    </>
  )
}
