import React, { useState, useContext, useEffect, useRef } from 'react';
import ReactWaves from '@dschoon/react-waves';
import { Context } from '../contextSubtitles';
import { getFolderStartTime } from '../utils';

export function PlayerSubtitles() {
  const wavesContainerRef = useRef(null);
  const context = useContext(Context);

  const zoomLevel = 100 - context.state['zoomLevel'];

  const [wavesurfer, setWavesurfer] = useState(null);
  // const [playing, setPlaying] = useState(false);
  const [pos, setPos] = useState(0);


  const [zoomLevelLocal, setZoomLevelLocal] = useState(zoomLevel);

  const [playbackTime, setPlaybackTime] = useState(0);


  const onLoading = ({ wavesurfer }) => {
    setWavesurfer(wavesurfer);
    const duration = wavesurfer.getDuration(); // in seconds
    // console.log('duration = ', duration);

    // Extract zoom fragment from context
    // const [startTime, endTime] = context.state['zoomFragment'].map(time => time / 1000); // Convert to seconds
    // const fragmentDuration = endTime - startTime;

    // if (duration > 0 && fragmentDuration > 0) {
    //   const containerWidth = wavesContainerRef.current ? wavesContainerRef.current.offsetWidth : 0;
    //   const pixelsPerSecondForFragment = containerWidth / fragmentDuration;
    //   // wavesurfer.zoom(pixelsPerSecondForFragment);
    //   // wavesurfer.seekTo(startTime / duration);
    // }
    // // Calculate container width dynamically
    const containerWidth = wavesContainerRef.current ? wavesContainerRef.current.offsetWidth : 0;
    // // Calculate the desired pixels per second
    const desiredPixelsPerSecond = duration > 0 ? containerWidth / duration : 1;
    setZoomLevelLocal(desiredPixelsPerSecond);
  }

  useEffect(() => {

  }, [context.state.mediaId, context.state.folder])

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.zoom();
    }
  }, [context.state.zoomLevel])

  const onPosChange = (newPos, wavesurfer) => {
    if (newPos !== pos) {
      setPos(newPos);
    }
  };

  const handleReady = () => {
    context.handles.setPlayerReady(true);
  };

  // const handleAudioProcess = () => {
  //   // console.log("audioprocess event triggered");
  //   let time = wavesurfer.getCurrentTime() * 1000;
  //   // console.log('time = ', time);
  //   // context.handles.setPlaybackTime(time);
  // };

  const handleAudioProcess = (() => {
    let lastCall = 0;
    return () => {
      const now = Date.now();
      if (now - lastCall >= 100) {
        lastCall = now;
        let time = wavesurfer.getCurrentTime() * 1000;
        setPlaybackTime(time);
      }
    };
  })();



  /*useEffect(() => {
    if (wavesurfer && context.debug.debugFullFunctionality) {
      wavesurfer.on('audioprocess', () => {
        handleAudioProcess()
        // let time = wavesurfer.getCurrentTime() * 1000;
        // context.handles.setPlaybackTime(time);
        // setPlaybackTime(time);
      });
    }

    return () => {
      if (wavesurfer) {
        wavesurfer.un('audioprocess', handleAudioProcess);
      }
    };
  }, [wavesurfer]);*/

  useEffect(() => {
    if (wavesurfer && typeof context.state.playStart === 'number') {
      const duration = context.state.audioDuration;
      const newStart = context.state.playStart / duration
      if (newStart <= 1 && newStart >= 0) {
        wavesurfer.seekTo(newStart);
      }
      context.handles.setPlayStart()
    }
  }, [context.state.playStart])


  const seek = (position) => {
    let currentTime = position * wavesurfer.getDuration();
    console.log('currentTime = ', currentTime);
    context.handles.setPlayStart(currentTime * 1000);
    if (context.state.translationDataAll) {
      const startTime = getFolderStartTime(context.state.translationDataAll, context.state.folder - 1);
      context.handles.setPlayOriginalStart(startTime + currentTime * 1000);
    }
  }


  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on('audioprocess', handleAudioProcess);
      wavesurfer.on('seek', seek);
    }
    return () => {
      if (wavesurfer) {
        wavesurfer.un('audioprocess', handleAudioProcess);
      }
    };
  }, [wavesurfer, context.debug.debugFullFunctionality])

  const wavesurferRef = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const duration = wavesurfer.getDuration()
    const relativeX = e.nativeEvent.offsetX / wavesContainerRef.current.offsetWidth;
    wavesurfer.seekTo(relativeX);
    setPos(relativeX * duration);
    context.handles.setPlaying(true);
  }

  return (<>
    {/* {context.debug.debugFullFunctionality && <TimeNumbers playbackTime={playbackTime} />} */}



    <div
      className="x_player0 x_player0--v"
      style={{
        left: 90,
      }}
    // className={context.state.subtitleMode ? "x_player0 x_player0--v" : "x_player0"}
    >

      <div className='x_player'
        style={{
          transform: 'rotate(90deg)',
          width: context.state['audioDuration'] ? `${context.state['audioDuration'] / zoomLevel}px` : '100%',
          position: 'absolute',
          top: context.state['audioDuration'] ? `${context.state['audioDuration'] / zoomLevel / 2 - 72 - 50}px` : 0,
          // left: 0,
          // 72 is the height of the header, and 50 is subtracted because the height of the player is 200 (not 100). After rotation, we need to subtract 50.
        }}
      >

        {/* <div className="x_player_control">
          <div className="x_player_control_play" onClick={() => setPlaying(!playing)}>
            {playing
              ? <div className="ico ico--40"><div>pause</div></div>
              : <div className="ico ico--40"><div>play_arrow</div></div>}
          </div>
        </div> */}
        <div className="x_player_vert" ref={wavesContainerRef}

          style={{
            // border: '1px solid red',
            // transform: 'rotate(90deg)',
            // width: '100%',
            // height: context.state.video !== 'full' ? 'auto' : 10,
            overflowY: 'hidden',
            // width: context.state['audioDuration'] ? `${context.state['audioDuration'] / zoomLevel}px` : '100%',
            // overflowX: 'auto',
            // overflowY: 'hidden',
            whiteSpace: 'nowrap',
            // transform: 'rotate(90deg)',
            borderRight: context.state.durationFull > context.state.audioDuration ? '2px dotted red' : 'none',
          }}
        >

          {/* {!context.state.mp3 && <Preloader0 height={'100%'}><PreloaderGif /></Preloader0>} */}
          {/* {!context.state.mp3 && <div style={{ height: 60, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 100, position: 'relative' }}>
            <div>Загрузка...</div>
          </div>} */}
          {!context.state.mp3 &&
            <div style={{ padding: 14 }}>Загрузка...</div>
          }

          {context.state.mp3 && <ReactWaves
            interact={false}
            ref={wavesurferRef}
            audioFile={context.state['mp3']}
            className={'react-waves'}
            options={{
              barGap: 1,
              barWidth: 2,
              barHeight: 2,
              // barRadius: 3,
              cursorWidth: 0,
              height: 242,
              hideScrollbar: true,
              progressColor: '#ff7606',
              responsive: false,
              waveColor: '#D1D6DA',
            }}
            // zoom={zoomLevelLocal}
            // zoom={100 - zoomLevel * ((90 - zoomLevel) / 10)}
            zoom={zoomLevel / 100}
            // zoom={1}
            volume={context.state['volumeOriginal'] / 100}
            pos={pos}
            playing={context.state['playing']}
            onPosChange={onPosChange}
            onLoading={onLoading}
            ready={handleReady}
          >
            {/* <Regions
              regions={{}}
            /> */}

          </ReactWaves>}
        </div>
      </div>

      <div
        className='x_player'
        onClick={handleClick}
        style={{
          height: 200,
          transform: 'rotate(90deg)',
          width: context.state['audioDuration'] ? `${context.state['audioDuration'] / zoomLevel}px` : '100%',
          position: 'absolute',
          opacity: 0,
          top: context.state['audioDuration'] ? `${context.state['audioDuration'] / zoomLevel / 2 - 72 - 50}px` : 0,
          // left: 0,
          // 72 is the height of the header, and 50 is subtracted because the height of the player is 200 (not 100). After rotation, we need to subtract 50.
        }}
          >
        </div>
      {/* <Time /> */}
    </div>


  </>
  );
}
