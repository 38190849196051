import React, { useContext, useRef } from "react";
import "../../add2.css";
import "../../Reels/scrollbar-dark.css";
import { Tracking } from "./components/Tracking";

import { VideosContext } from "../../../providers/VideosProvider/videosProvider";
import { VideosMenu } from "./components/VideosMenu";
import { VideosHead } from "./components/VideosHead";
import { Player } from "./components/Player";
import { FragmentPlayer } from "./components/FragmentPlayer";

export const Videos = React.memo(() => {
  const context = useContext(VideosContext);
  console.log("context", context);
  const playerRef = useRef(null);
  const fragmentPlayerRef = useRef(null);

  return (
    <div className="App b0">
      <div className="c00">
        <VideosMenu />

        <div className="c0">
          <VideosHead playerRef={playerRef} />

          <div className="c c--video">
            {/*{context.state.reelsFrame && (*/}
            {/*  <div className="x_reels0" style={{ opacity: 0.85 }}>*/}
            {/*    <div className="x_reels_lr" />*/}
            {/*    <div className="x_reels_frame" />*/}
            {/*    <div className="x_reels_lr" />*/}
            {/*  </div>*/}
            {/*)}*/}

            {/* {context.fragments.map(one => <ReelsFragmentPlayer key={one.id} playingFragment={one.id} />)} */}

            <Player playerRef={playerRef} fragmentPlayerRef={fragmentPlayerRef} />
            {/*<FragmentPlayer playerRef={playerRef} fragmentPlayerRef={fragmentPlayerRef} />*/}
          </div>

          <Tracking />

          {/* <Footer context={context} view={['zoomLevel']} /> */}

          <DebugReels />
        </div>
      </div>
    </div>
  );
});

const DebugReels = ({}) => {
  const context = useContext(VideosContext);
  return (
    <div className="_f _t08">
      <div className="_f">Duration (ms):{context.videoDuration}</div>
      <div className="_f">Current time (ms):{context.state.currentTime}</div>
      <div className="_f">
        <div
          className="x_button x_button--ghost _t08"
          onClick={() => {
            localStorage.clear();
          }}
        >
          localStorage.clear()
        </div>
      </div>
    </div>
  );
};
