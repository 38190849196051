import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../context';
import { Head } from './Head';
import { Layout } from './Layout';
import '../trans.css';
import { Input } from 'antd';
import { TranslateLine } from './TranslateLine';
import { PlayerTranslate } from './PlayerTranslate';
import './scrollbar-lite.css';

const { TextArea } = Input;

export function LayoutTranslate(props) {
  const context = useContext(Context);
  const navigate = useNavigate();


  const [tracks, setTracks] = useState([]);
  const [editing, setEditing] = useState();


  useEffect(() => {
    if (!tracks.length && context.state['translationData'].length
      || (context.state['translationData'].length && context.state['translationData'][0].folder !== context.state.folder)
    ) {
      setTracks(context.state['translationData'])
    }
  }, [context.state.translationData, context.state.folder])

  return (
    <Layout context={context}>

      <Head
        playSpacebar={false}
        videoToggleShown={false}
        saveShown='changedTranslation'
        context={context}
      />

      <div className="xt_layout">
        <div className="xt_translate">
          <PlayerTranslate />


          {tracks.map((oneTrack, ind, array) => {

            return (<TranslateLine
              key={`track_${oneTrack['id']}_${ind}`}
              track={oneTrack}
              editing={editing}
              setEditing={setEditing}
            />)

          })}

        </div>
      </div>

    </Layout>
  )
}