import styled, { css } from "styled-components";

export const OptionsContainerSC = styled.div`
  grid-column-gap: 0.2em;
  grid-row-gap: 0.2em;
  flex-flow: column;
  align-items: stretch;
  width: 100%;
  margin-top: 0.4em;
  margin-bottom: 0.4em;
  line-height: 1.2em;
  display: flex;
`;

export const OptionsLineSC = styled.div`
  grid-column-gap: 0.6em;
  grid-row-gap: 0.6em;
  width: 100%;
  display: flex;
`;

export const OptionsLabelSC = styled.div`
  color: var(--reels-txt);
  white-space: nowrap;
`;

export const OptionsValueSC = styled.div<{ ellipsis?: boolean }>`
  white-space: nowrap;
  flex: 0 auto;
  max-width: 100%;
  max-height: 1.2em;
  overflow: hidden;
  ${({ ellipsis }) => ellipsis && Ellipsis}
`;

export const Ellipsis = css`
  max-width: 100%;
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
