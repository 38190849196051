import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled, { css } from 'styled-components';

export function Preloader(props) {
  return (
    <>
      <Preloader0>
        <PreloaderGif {...props} />
        Загрузка...
      </Preloader0>
      {/* <div className="">
        Loading...
      </div> */}
    </>
  )
}






export const PreloaderTracks = styled.div`
  height: 100%;
  display: flex;
  z-index: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.4);
}`;




export const Preloader0 = styled.div`
  min-height: ${({ height }) => (height ? height : "80svh")};
  height: 200px;
  display: flex;
  z-index: 100;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // row-gap: 1em;
`;

export const PreloaderGif = styled.div`
  mix-blend-mode: overlay;
  opacity: 0.8;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('https://res.cloudinary.com/du6naw4zf/image/upload/v1697827495/misha/Spinner-1.5s-244px_v98d12.gif');
  background-repeat: no-repeat;
  background-size: 200%;
  background-position: 50% 50%;
  width:${({ width, size }) => width || size ? `${width || size}px` : '80px'};
  height:${({ height, size }) => height || size ? `${height || size}px` : '80px'};
`;

export const PreloaderPercent = styled.div`
  position: absolute;
  font-size: 0.8em;
  color: #ffffff63;
  margin-top: 3em;
`;
