import React, { useRef, useState, useCallback, useContext, useEffect } from 'react';
import ReactWaves from '@dschoon/react-waves';
import { Context } from '../context';
import { Database } from '../utils';
import { useIndexedDB } from '../hooks/useIndexedDb';

export const PlayerTranslated = React.memo(({
  length,
  width,
  id,
  ind,
  handleClick = () => void (0),
  handlePlayNext = () => void (0),
  mp3,
  end_rus_milli,
  trimmed,
  options = {
    barHeight: 2,
    barRadius: 3,
    cursorWidth: 0,
    height: 60,
    hideScrollbar: true,
    progressColor: '#ff7606',
    responsive: true,
    waveColor: '#D1D6DA',
    interact: false,
    dragSelection: false
  }
}) => {
  const context = useContext(Context);
  const wavesRef = useRef(null);
  const zoomLevel = 100 - context.state['zoomLevel'];

  const [wavesurfer, setWavesurfer] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [pos, setPos] = useState(0);
  const [vaw, setVaw] = useState(0);

  const {
    putValue,
    getValue,
    isDBConnecting,
  } = useIndexedDB(Database.name, [Database.audioTable]);

  useEffect(() => {
    if (isDBConnecting) return;
    fetchVawCached(mp3, putValue, getValue);
  }, [mp3, isDBConnecting])

  const onLoading = ({ wavesurfer }) => {
    setWavesurfer(wavesurfer);
  };

  const onWaveformReady = ({ wavesurfer }) => {
    setWavesurfer(wavesurfer);
  };

  const onPosChange = useCallback((newPos, wavesurfer) => {
    if (newPos !== pos) {
      setPos(newPos);
      setWavesurfer(wavesurfer);
    }
  }, [pos]);

  const secondsToPosition = useCallback((sec) => {
    return 1 / wavesurfer.getDuration() * sec;
  }, [wavesurfer]);

  const zoom = useCallback((direction) => {
    const currentZoom = wavesurfer.params.minPxPerSec;
    if (direction === 'in') {
      wavesurfer.zoom(currentZoom + 1);
    } else if (direction === 'out' && currentZoom > 1) {
      wavesurfer.zoom(currentZoom - 1);
    }
  }, [wavesurfer]);

  const handleRegionClick = useCallback((e) => {
    setTimeout(() => {
      wavesurfer.seekTo(secondsToPosition(e.originalArgs[0].start));
    }, 50);
  }, [wavesurfer, secondsToPosition]);

  const handleResetAndToggle = () => {
    if (typeof context.state.playStart === 'number' && context.state.playingFragment === id) {
      wavesurfer.seekTo(0);
    }
  };

  useEffect(() => {
    setPos(0);

    if (wavesurfer && typeof context.state.playStart === 'number') {
      wavesurfer.seekTo(0);
    }

    let timeoutId;
    if (trimmed && context.state['playingFragment'] === id) {
      timeoutId = setTimeout(() => {
        handlePlayNext(ind);
      }, length);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };

  }, [context.state['playingFragment'], context.state.playStart, trimmed, id, ind, length, wavesurfer, handlePlayNext]);

  const [audioUrl, setAudioUrl] = useState(`${mp3}?cache=${Date.now()}`);

  const fetchVawCached = async (mp3, putValue, getValue) => {
    setVaw(null);
    try {
      const blob = await getVaw(mp3, putValue, getValue);
      setVaw(blob);
      
    } catch(error) {
      console.error("Error fetching MP3: ", error);
    };
  }

  const getVaw = async (mp3, putValue, getValue) => {
    const blobFromInexedDB = await getValue(Database.audioTable, mp3);
    if (blobFromInexedDB) {
      return blobFromInexedDB.content;
    }
    const response = await fetch(mp3);
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    const blob = await response.blob();
    await putValue(
      Database.audioTable,
      { mediaId: null,
        folder: null,
        content: blob,
        id: mp3,
      }
    );
    return blob;
  }

  const recacheAudio = useCallback(() => {
    setAudioUrl(`${mp3}?cache=${Date.now()}`);
  }, [mp3]);

  useEffect(() => {
    recacheAudio();
  }, [end_rus_milli, recacheAudio]);

  return (
    <div
      style={{
        paddingTop: '0.26em',
        width: width
      }}
      onClick={handleResetAndToggle}
    >
      <div style={{
        top: 0, bottom: 0, left: 0, right: 0, zIndex: 1, position: 'absolute'
      }} />

      {vaw && <ReactWaves
        key={audioUrl}  // Use audioUrl as key to force re-render
        ref={wavesRef}
        audioFile={vaw}
        className={'react-waves'}
        options={{ ...options }}
        volume={context.state['volumeTranslated'] / 100}
        zoom={(100 - context.state.zoomLevel) / 100}
        pos={pos}
        playing={context.state['playingFragment'] === id}
        onLoading={onLoading}
        onFinish={() => {
          handlePlayNext(ind);
        }}
      />}
    </div>
  );
});
