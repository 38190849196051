import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled, { css } from 'styled-components';
import { Context } from '../context';
import { HorizontalSlider } from './HorizontalSlider';
import { VolumeCross } from './VolumeCross';

export function Debug(props) {
  const context = useContext(Context);
  return (
    <>



      <div className="mobile_v_no"
        style={{
          backgroundColor: '#ccc',
        }}
      >

        <div
          className="x_flex-h"
          style={{
            opacity: 0.4,
            // position: 'fixed',
            // bottom: 0,
            fontSize: '0.7em',

            // left: 0,
            // right: 0,
          }}>


          {/* <div className="x_text-08">
            Сегменты:

          <HorizontalSlider
            value={context.state['activeSegmentLength']}
            min={4}
            max={50}
            // onValueChange={(val) =>
            //   Math.floor(context.handles['setActiveSegmentLength'](val + 1) / 10) * 10
            // }
            onValueChange={(val) => context.handles['setActiveSegmentLength'](val)}
          />
        </div> */}


          {/* X1: {context.state['zoomFragment'][0]}&nbsp;|&nbsp; */}
          {/* X2: {context.state['zoomFragment'][1]}&nbsp;|&nbsp; */}
          {/* Scrubber width: {context.state.scrubberWidth}&nbsp;|&nbsp; */}

          <label className='x_flex-h'><input type='checkbox' checked={context.state.videoPlayerType === 'simple'} onChange={() => context.handles.setVideoPlayerType(context.state.videoPlayerType === 'simple' ? 'advanced' : 'simple')} /> Простой видео плеер</label>
          &nbsp;|&nbsp;
          Trimmed fragments: {context.state.translationData && context.state.translationData.filter(item => item.trimmed).length} <ScrollToTrimmed />
          &nbsp;|&nbsp;
          Последний фрагмент выходит за границы: {context.state['lastFragmentTrimmed'] ? <b style={{ color: 'red' }}>Да</b> : <b>Нет</b>}
          &nbsp;|&nbsp;
          Length: {context.state['audioDuration']}&nbsp;|&nbsp;
          {/* MediaId: {context.state['mediaId']} */}
          {/* &nbsp;|&nbsp; */}
          Playback Time: {context.state['playbackTime']}
          &nbsp;|&nbsp;
          Current: {context.state['playingFragment']}
          &nbsp;|&nbsp;
          Next: {context.state[' ']}
          {/* &nbsp;|&nbsp; */}
          {/* <label className='x_flex-h' style={{ marginBottom: 0 }}><input type='checkbox' checked={context.debug.debugFullFunctionality} onChange={() => context.handles.setDebugFullFunctionality(!context.debug.debugFullFunctionality)} /> Время проигрывания</label> */}

          {/* <div className="x_flex-h">
            <img src="https://app.netlify.com/access-control/bb-api/api/v1/badges/21d7ff1d-7d29-402e-8bf6-97ed7fa7fc25/deploy-status" alt="Deploy status badge" height={20} />
          </div> */}
      </div>
      </div>

    </>
  )
}


const ScrollToTrimmed = () => {
  const context = useContext(Context);
  const scrollTo = (id) => {
    context.handles.scrollToElement(id)
  }

  return (
    <>
      <select
        style={{ width: '100px' }}
        // value={voice}
        onChange={(e) => {
          scrollTo(e.target.value)
        }}
      >
        <option value={'-'} key={`trimmed_00`}>—</option>
        {context.state.translationData && context.state.translationData.filter(item => item.trimmed).map((one, index) => <option value={one.id} key={`trimmed_${index}`}>{one.id}</option>)}
      </select>
    </>
  )
}